import React from "react";
import { SvgProps, Svg } from "@raca2022/uikit";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 40 40" fill="#fff" {...props}>
      <path
        d="M20 0C31.04 0 40 8.96 40 20C40 31.04 31.04 40 20 40C8.96 40 0 31.04 0 20C0 8.96 8.96 0 20 0ZM11.94 21.06L17.94 27.06C18.24 27.36 18.62 27.5 19 27.5C19.38 27.5 19.76 27.36 20.06 27.06C20.64 26.48 20.64 25.52 20.06 24.94L16.62 21.5H27C27.82 21.5 28.5 20.82 28.5 20C28.5 19.18 27.82 18.5 27 18.5H16.62L20.06 15.06C20.64 14.48 20.64 13.52 20.06 12.94C19.48 12.36 18.52 12.36 17.94 12.94L11.94 18.94C11.36 19.52 11.36 20.48 11.94 21.06Z"
        fill="#A9A9A9"
      />
    </Svg>
  );
};

export default Icon;
