import React from "react";
import { SvgProps, Svg } from "@raca2022/uikit";

const OpenMenu: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 19 19" fill="#fff" {...props}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M2.84888 14.1448H16.4577V12.6327H2.84888V14.1448ZM2.84888 10.3646H16.4577V8.85251H2.84888V10.3646ZM2.84888 5.07227V6.58436H16.4577V5.07227H2.84888Z"
        fill="white"
      />
    </Svg>
  );
};

export default OpenMenu;
