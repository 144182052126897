import React from "react";
import { Provider } from "react-redux";
import { Web3ReactProvider, createWeb3ReactRoot } from "@web3-react/core";
import { ModalProvider, ModalProviderNone } from "@raca2022/uikit";
import { MenuProvider } from "./components/Menu/context/Context";
import { store } from "./redux/configStore";

import { getLibrary, Web3ProviderNetwork } from "./utils/context/web3provider";

type Props = {
  children: React.ReactNode;
};

const Providers: React.FC<Props> = ({ children }) => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <Provider store={store}>
          <ModalProvider>
            <ModalProviderNone>
              <MenuProvider>{children}</MenuProvider>
            </ModalProviderNone>
          </ModalProvider>
        </Provider>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  );
};

export default Providers;
