import { Box, Flex } from "@raca2022/uikit";
import styled from "styled-components";
// import BG from "../../assets/imgQuata/Home/backgroundOutteam.png";

export const FootterAndOutTeam = styled(Box)`
  width: 100%;
  height: 100%;
  position: relative;

  :after {
    content: "";
    position: absolute;
    top: -50%;
    left: 0px;
    width: 100%;
    height: 660px;
    background: linear-gradient(
      rgba(53, 228, 215, 0) 0%,
      rgb(28, 102, 97) 32.3%,
      rgb(52, 14, 56) 59.9%,
      rgb(0, 0, 0) 100%
    );
    z-index: -1;
  }

  @media only screen and (max-width: 768px) {
    :after {
      top: 0%;
      height: 100%;
    }
  }
`;
export const FooterAndOutTeamContainer = styled(Box)`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
`;
export const Outteam = styled(Flex)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: auto;
  padding-top: 20px;
  ::-webkit-scrollbar {
    display: none;
  }
`;
export const OutteamFloor = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 1348px;
  padding: 0 46px;
  @media only screen and (max-width: 769px) {
    padding: 0 12px;
  }
`;
export const Title = styled(Flex)`
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  padding: 0 46px;
  padding-top: 50px;
  h1 {
    font-size: 48px;
    font-family: Devantor;
    font-weight: 700;
    letter-spacing: 0.02em;
    text-align: center;
    background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(0, 0, 0, 0));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media only screen and (max-width: 769px) {
    padding: 0 12px;
    margin-top: 40px;
  }
  @media only screen and (max-width: 640px) {
    h1 {
      font-size: 30px;
    }
  }
`;
export const Teams = styled(Box)`
  width: 23%;
  max-width: 323px;
  height: 157px;
  border-radius: 8px;
  padding-top: 45px;
  padding-left: 25px;
  position: relative;
  p {
    font-size: 10px;
    font-family: Monda;
    font-weight: 400;
    line-height: 17px;
    margin-top: 10px;
  }
  h1 {
    font-size: 16px;
    font-family: Monda;
    font-weight: 400;
    line-height: 16px;
    margin-top: 10px;
  }
  img {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;
export const FootterFootball = styled(Flex)`
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 85px;
  padding-bottom: 40px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const FooterItem = styled(Box)`
  width: 25%;
  h1 {
    font-family: Monda;
    color: #cdcec5;
    font-size: 16px;
    line-height: 18px;
    /* font-weight: 700; */
    letter-spacing: 2px;
    margin-bottom: 26px;
    position: relative;
  }
  p {
    font-family: MondaBold;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 2px;
    color: #cdcec5;
    margin-bottom: 16px;
    font-weight: 500;
  }
  img {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    transition: all 0.3s linear;
  }
  @media only screen and (max-width: 768px) {
    width: 50%;
    margin-bottom: 30px;
    padding-left: 30px;
    h1,
    p {
      text-align: center;
    }
  }
  @media only screen and (max-width: 475px) {
    width: 100%;
    h1,
    p {
      text-align: left;
    }
  }
`;

export const SOCIAL = styled(Flex)`
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 28px;
  flex-wrap: wrap;

  @media only screen and (max-width: 500px) {
    gap: 0;
    row-gap: 30px;

    & > * {
      width: 25%;
      text-align: center;
    }
  }

  img {
    max-width: 34px;
    max-height: 27px;
    /* margin-right: 30px; */
    :last-child {
      margin: 0px;
    }
  }
`;
export const CoppyRight = styled(Box)`
  width: 100%;
  padding-top: 30px;
  /* padding-bottom: 26px; */
  p {
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: Monda;
    font-weight: 400;
    line-height: 21px;
    color: #cdcec5;
    margin-bottom: 12px;
  }
`;
export const FooterLogo = styled(Box)`
  width: 144px;
  margin-right: 60px;
  img {
    max-width: 100%;
  }

  @media only screen and (max-width: 768px) {
    margin-right: 0px;
    padding-bottom: 60px;
  }
`;
export const FooterConten = styled(Flex)`
  flex: 1;
  align-items: flex-start;
  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
  @media only screen and (max-width: 475px) {
    flex-direction: column;
    width: 100%;
  }
`;
export const FooterItemElm = styled(Box)<{ height?: string }>`
  height: fit-content;

  p {
    cursor: pointer;
  }

  a {
    font-family: MondaBold;
    font-size: 12px;
    line-height: 18px;
    -webkit-letter-spacing: 2px;
    -moz-letter-spacing: 2px;
    -ms-letter-spacing: 2px;
    letter-spacing: 2px;
    color: #cdcec5;
    margin-bottom: 16px;
  }
  @media only screen and (max-width: 475px) {
    height: ${({ height }) => height};
    overflow: hidden;
    transition: all 0.3s linear;
  }
`;
