import React from "react";
import { SvgProps, Svg } from "@raca2022/uikit";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" fill="#fff" {...props}>
      <path
        d="M13.924 8.30859L17.3086 12.3086M17.3086 12.3086L13.924 16.3086M17.3086 12.3086L6.80859 12.3086"
        stroke="url(#paint0_linear_95_76447)"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_95_76447"
          x1="12.0586"
          y1="9.76814"
          x2="12.0573"
          y2="14.9077"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C80E0E" />
          <stop offset="1" stop-color="#AE1717" />
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default Icon;
