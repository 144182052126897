import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actGetPriceToken } from "../../redux/action/AuthManagerApi";
import { useActiveWeb3React } from "../../hooks";

const useGetPrice = () => {
  const dispatch = useDispatch();
  const { account } = useActiveWeb3React();
  const { listGetPrice } = useSelector((state: any) => state.auth);

  useEffect(() => {
    (async () => {
      // if (account) {
      const _timmer = await setTimeout(() => {
        dispatch(actGetPriceToken());
      }, 500);
      return () => {
        clearTimeout(_timmer);
      };
      // }
    })();
  }, [account]);

  localStorage.setItem("price", listGetPrice);
};

export default useGetPrice;
