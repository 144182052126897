import * as ActionType from "../contants/AuthContanst";
import { TOKEN } from "../settings/config";
import { authManagerServices } from "../service/apiAuth";

export const actLoginAdmin = (_login: any) => {
  return async (dispatch: any) => {
    dispatch(actLoginRequest());
    try {
      const result = await authManagerServices.loginServices(_login);
      const globalUser = result.data;
      const statusCode = result.data.StatusCode;
      if (statusCode && statusCode === 200) {
        dispatch(actLoginSuccess(globalUser));
        const tokenAsscess = result.data.Data.Token;
        localStorage.setItem(TOKEN, tokenAsscess);
      } else {
        dispatch(actLoginFailed(globalUser));
      }
    } catch (error) {
      dispatch(actLoginFailed(error));
    }
  };
};

export const actLoginRequest = () => ({
  type: ActionType.LOGIN_REQUEST,
});

export const actLoginSuccess = (userLogin: any) => ({
  type: ActionType.LOGIN_SUCCESS,
  payload: userLogin,
});

export const actLoginFailed = (error: any) => ({
  type: ActionType.LOGIN_FAILED,
  payload: error,
});
