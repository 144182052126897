import React from "react";
import { SvgProps, Svg } from "@raca2022/uikit";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 25 25" fill="#fff" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.7712 3.64844H19.8102C20.9179 3.64844 21.8158 4.54636 21.8158 5.65401V19.693C21.8158 20.8007 20.9179 21.6986 19.8102 21.6986H5.7712C4.66355 21.6986 3.76562 20.8007 3.76562 19.693V5.65401C3.76562 4.54636 4.66355 3.64844 5.7712 3.64844ZM8.77955 18.6902C9.05646 18.6902 9.28094 18.4657 9.28094 18.1888V11.1693C9.28094 10.8925 9.05646 10.6679 8.77955 10.6679H7.27537C6.99846 10.6679 6.77398 10.8925 6.77398 11.1693V18.1888C6.77398 18.4657 6.99846 18.6902 7.27537 18.6902H8.77955ZM8.02746 9.66515C7.19673 9.66515 6.52328 8.99171 6.52328 8.16097C6.52328 7.33023 7.19673 6.65679 8.02746 6.65679C8.8582 6.65679 9.53164 7.33023 9.53164 8.16097C9.53164 8.99171 8.8582 9.66515 8.02746 9.66515ZM18.306 18.6902C18.5829 18.6902 18.8074 18.4657 18.8074 18.1888V13.576C18.84 11.9824 17.6618 10.6223 16.0798 10.4273C14.9738 10.3262 13.9021 10.8428 13.2921 11.771V11.1693C13.2921 10.8925 13.0676 10.6679 12.7907 10.6679H11.2865C11.0096 10.6679 10.7851 10.8925 10.7851 11.1693V18.1888C10.7851 18.4657 11.0096 18.6902 11.2865 18.6902H12.7907C13.0676 18.6902 13.2921 18.4657 13.2921 18.1888V14.4284C13.2921 13.5977 13.9656 12.9242 14.7963 12.9242C15.627 12.9242 16.3004 13.5977 16.3004 14.4284V18.1888C16.3004 18.4657 16.525 18.6902 16.8018 18.6902H18.306Z"
        fill="#F2F2F2"
      />
    </Svg>
  );
};

export default Icon;
