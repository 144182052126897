import React from "react";
import { SvgProps, Svg } from "@raca2022/uikit";

const LogoRaca: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 21" fill="none" {...props}>
      <path
        d="M9.40215 13.2844L18.2964 19.8556C19.3115 20.4157 20.0438 20.1256 20.2967 18.9134L23.9172 1.85261C24.2878 0.366553 23.3507 -0.307692 22.3796 0.13316L1.12049 8.33058C-0.330623 8.91269 -0.321979 9.72229 0.856018 10.083L6.31159 11.7859L18.9418 3.8176C19.5381 3.45601 20.0854 3.65023 19.6363 4.04897"
        fill="#EA6E6E"
      />
    </Svg>
  );
};

export default LogoRaca;
