import { Api } from "./baseApi";

class AuthManagerServices {
  loginServices = (_login: any) => {
    return Api.post(`/api/login`, _login);
  };
  logoutServices = (_logout: any) => {
    return Api.post(`/api/logout`, _logout);
  };
}

export const authManagerServices = new AuthManagerServices();

class AuthManagerApi {
  getListDashboard = () => {
    return Api.get(`/api/dashboard/get`);
  };

  LichSuGiaoDich = (infoLichSu: any) => {
    return Api.post(`/api/user/transactions`, infoLichSu);
  };

  getListAffiliated = () => {
    return Api.get(`/api/affiliate/statistic`);
  };

  SwapRequest = (infoSwap: any) => {
    return Api.post(`/api/swap`, infoSwap);
  };
  WithdrawRequest_Submit = (infoWithdrawSubmit: any) => {
    return Api.post(`/api/withdraw/submit`, infoWithdrawSubmit);
  };
  ProductSanPham = (infoProduct: any) => {
    return Api.post(`/api/user/investment`, infoProduct);
  };
  getPriceToken = () => {
    return Api.get(`/api/token/price`);
  };

  getWalletAddress = () => {
    return Api.get(`/api/ReceiveAddress`);
  };

  InvestmentHistory = (infoLichSu: any) => {
    return Api.post(`/api/user/investmenthistory`, infoLichSu);
  };
  Refund = (refund: any) => {
    return Api.post(`/api/withdraw/refund`, refund);
  };
}

export const authManagerApi = new AuthManagerApi();
