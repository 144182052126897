import React, { memo, useState } from "react";
import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
import { Flex, Box, Text, IconButton } from "@raca2022/uikit";
import debounce from "lodash.debounce";

import { useMenuContext } from "../context/Context";
import { OpenMenu, TelegramIcon, FacebookIcon, TwitterIcon, CloseConnectIcon } from "../../Svg";

import { ArrClone } from "./config";

const MobileLogo = memo(() => {
  return (
    <React.StrictMode>
      <Flex>
        <Box className="desktop-icon">
          <IconOrbit src="/assets/Logo/LogoFt.png" alt="icon-logo" />
        </Box>
        <Box className="desktop-text" pl="16px">
          {/* <Heading>Footdex</Heading> */}
          <HeadingV1>footdex</HeadingV1>
        </Box>
      </Flex>
    </React.StrictMode>
  );
});

const MenuListMobile = () => {
  const { closeSidebar, isSidebarOpen }: any = useMenuContext();
  const [isArr] = useState(ArrClone);

  return (
    <WrapperMobile isSidebarOpen={isSidebarOpen}>
      <Box width="100%" height="100%" className={`blur cus-modal ${isSidebarOpen ? "cus-showModal" : ""}`}>
        <Box position="absolute" top="30px" left="12px">
          <IconButton
            className="bt-mobile"
            onClick={() => closeSidebar()}
            startIcon={<CloseConnectIcon width="30px" />}
          />
        </Box>
        <Flex height="100%" pt="20px" justifyContent="flex-start" flexDirection="column" alignItems="center">
          <Link to="/" onClick={() => closeSidebar()}>
            <MobileLogo />
          </Link>
          <Flex height="100%" justifyContent="center" flexDirection="column" alignItems="center" mt="-43px">
            {isArr.map((_item, _index) => {
              return (
                <Box pb="36px">
                  <Link to={_item.href} onClick={debounce(closeSidebar, 200)}>
                    <Text bold fontFamily="'Panchang'" textAlign="center" color="#A9A9A9" as="h1">
                      {_item.title}
                    </Text>
                  </Link>
                </Box>
              );
            })}
          </Flex>
        </Flex>
        <MenuFt>
          <Link to="/">
            <IconButton className="bt-mobile" startIcon={<TwitterIcon width="30px" height="26px" />} />
          </Link>
          <Link to="/">
            <IconButton className="bt-mobile" startIcon={<FacebookIcon width="30px" height="26px" />} />
          </Link>
          <Link to="/">
            <IconButton className="bt-mobile" startIcon={<TelegramIcon width="30px" height="26px" />} />
          </Link>
        </MenuFt>
      </Box>

      {/* <div className="blur">
        <h1>My Resume</h1>
      </div>
      <div className="blur">
        <h2>“Innovation distinguishes between a leader and a follower” ― Steve Jobs</h2>
      </div> */}
    </WrapperMobile>
  );
};

export default React.memo(MenuListMobile);

const WrapperMobile = styled.div<{ isSidebarOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  transition: height 0.4s ease;
  height: ${({ isSidebarOpen }) => (isSidebarOpen ? "100%" : "0%")};
  background-color: rgba(28, 28, 28, 0.8);
  z-index: 110;

  border-radius: 4px;
  backdrop-filter: blur(20px) saturate(180%);
  -webkit-backdrop-filter: blur(20px) saturate(180%);

  &::before {
    border-radius: 4px;
    backdrop-filter: blur(20px) saturate(180%);
    -webkit-backdrop-filter: blur(20px) saturate(180%);
  }

  .bt-mobile {
    background: transparent;
    height: auto;
    box-shadow: none;

    :hover:not(:disabled):not(.button--disabled):not(.button--disabled):not(:active) {
      box-shadow: none;
    }
  }

  .cus-modal {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease, visibility 0.4s ease;
  }

  .cus-showModal {
    opacity: 1;
    visibility: visible;
  }
`;

const Heading = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  font-family: "PanchangBold";
  display: inline-block;
  background: linear-gradient(180deg, #e70303 13.39%, #8e2121 92.19%, #fff 100%, #fff 47%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;
const HeadingV1 = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  font-family: Devator;
  display: inline-block;

  background: linear-gradient(119.25deg, #dfdfdf 11.11%, #909090 83.76%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const IconOrbit = styled.img`
  max-width: 100%;
  width: 42px;
  height: 100%;
  display: block;
`;

const MenuFt = styled.div`
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translateX(-50%);

  background: transparent;
  border-radius: 20px 20px 0px 0px;
  width: 310px;
  height: 80px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
