import { Flex, Box, Text } from "@raca2022/uikit";
import styled from "styled-components";
import { Button } from "../Button";

export const MenuWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 14px;
  /* background: rgba(28, 28, 28, 0.9); */
  z-index: 90;

  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  border-radius: 4px;
  transition: background-color 0.5s cubic-bezier(0.28, 0.11, 0.32, 1);
  transition-property: background-color, backdrop-filter, -webkit-backdrop-filter;

  &::before {
    backdrop-filter: blur(20px) saturate(180%);
    -webkit-backdrop-filter: blur(20px) saturate(180%);
    border-radius: 4px;
  }

  .fl-left {
    flex-basis: 100%;
  }

  @media only screen and (max-width: 1200px) {
    padding: 18px 14px;
  }

  @media only screen and (max-width: 768px) {
    padding: 10px;
  }
`;

export const ButtonConect = styled(Button)`
  border-radius: 10px;
  color: #fff;
  background: transparent;
  border: 1px solid #ffffff;

  :hover {
    color: #4eff00;
    background: transparent;
    border: 1px solid #4eff00;
  }
`;

export const WrapperMobile = styled.div`
  .bt-mobile {
    background: transparent;
    height: auto;
    padding-right: 10px;
    box-shadow: none !important;
  }
`;
