import React from "react";
import { SvgProps, Svg } from "@raca2022/uikit";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 80 80" fill="#fff" {...props}>
      <rect width="80" height="80" rx="40" fill="url(#paint0_linear_95_76481)" />
      <path
        d="M43.6175 59.0398C43.0108 59.2965 42.3575 59.5298 41.6575 59.7631L37.9708 60.9765C28.7075 63.9631 23.8308 61.4665 20.8208 52.2031L17.8341 42.9865C14.8475 33.7231 17.3208 28.8232 26.5841 25.8365L30.1775 24.6465C29.7341 25.7665 29.3608 27.0498 29.0108 28.4965L26.7241 38.2732C24.1575 49.2632 27.9141 55.3298 38.9041 57.9432L42.8241 58.8765C43.0808 58.9465 43.3608 58.9931 43.6175 59.0398Z"
        fill="url(#paint1_linear_95_76481)"
      />
      <path
        d="M52.0631 19.4892L48.1664 18.5792C40.3731 16.7359 35.7298 18.2525 32.9998 23.8992C32.2998 25.3225 31.7398 27.0492 31.2731 29.0325L28.9864 38.8092C26.6998 48.5625 29.7098 53.3692 39.4398 55.6792L43.3598 56.6125C44.7131 56.9392 45.9731 57.1492 47.1398 57.2425C54.4198 57.9425 58.2931 54.5359 60.2531 46.1125L62.5398 36.3592C64.8264 26.6059 61.8398 21.7759 52.0631 19.4892Z"
        fill="url(#paint2_linear_95_76481)"
      />
      <defs>
        <linearGradient id="paint0_linear_95_76481" x1="40" y1="0" x2="40" y2="80" gradientUnits="userSpaceOnUse">
          <stop stop-color="#3C3D3E" />
          <stop offset="1" stop-color="#3C3D3E" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_95_76481"
          x1="21.5576"
          y1="43.4093"
          x2="38.8925"
          y2="43.4132"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C80E0E" />
          <stop offset="1" stop-color="#AE1717" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_95_76481"
          x1="34.6469"
          y1="37.5838"
          x2="57.1382"
          y2="37.5901"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C80E0E" />
          <stop offset="1" stop-color="#AE1717" />
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default Icon;
