import React, { useMemo, useEffect, useState, useRef } from "react";
import { useModal, useMatchBreakpoints } from "@raca2022/uikit";
import { Button } from "../Button";
import { ConnectWallet } from "../Connect";
import { useActiveWeb3React, useLogout, useLogin, usePresentRiskDisclaimerRef } from "../../hooks";
import useGetPrice from "./useGetPrice"

import { ButtonConect } from "./styledMenu";

const Connect = () => {
  const { account } = useActiveWeb3React();
  const { isXl } = useMatchBreakpoints();

  const [onModalCustom] = useModal(<ConnectWallet />);
  // usePresentRiskDisclaimerRef();
  useLogin();
  useLogout();
  useGetPrice();

  const customAccount = useMemo<any>(() => {
    if (account) {
      return `${account.substr(0, 4)}...${account.substr(account.length - 4)}`;
    }
    if (!isXl) {
      return `Connect Wallet`;
    }
    if (isXl) {
      return `Connect`;
    }
  }, [account, isXl]);

  return (
    <React.StrictMode>
      <ButtonConect onClick={onModalCustom}>{customAccount}</ButtonConect>
    </React.StrictMode>
  );
};

export default Connect;
