import React from "react";
import { SvgProps, Svg } from "@raca2022/uikit";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 354 490" fill="none" {...props}>
      <path
        d="M27.5824 335.978C23.2527 333.403 23.3467 327.102 27.7513 324.657L165.674 248.093C167.558 247.047 169.837 246.998 171.764 247.962L326.136 325.148C330.616 327.388 331.01 333.63 326.846 336.415L189.767 428.117C187.674 429.517 184.962 429.589 182.798 428.302L27.5824 335.978Z"
        fill="url(#paint0_linear_172_124600)"
      />
      <path
        d="M31.1462 275.395C26.8165 272.819 26.9104 266.519 31.3151 264.074L169.238 187.51C171.122 186.464 173.401 186.415 175.328 187.379L329.699 264.564C334.18 266.805 334.573 273.047 330.41 275.832L193.331 367.534C191.238 368.934 188.526 369.006 186.362 367.719L31.1462 275.395Z"
        fill="white"
        fill-opacity="0.45"
      />
      <g clip-path="url(#clip0_172_124600)">
        <path
          d="M203.714 139.849L204.733 141.516L203.252 142.395V141.238L202.326 140.682L203.714 139.849Z"
          fill="#BAE4EF"
        />
        <path
          d="M119.867 141.238V142.442L118.34 141.516L119.358 139.849L120.793 140.682L119.867 141.238Z"
          fill="#BAE4EF"
        />
        <path
          d="M205.196 140.127V141.515H204.733L203.715 139.849L202.326 140.682L161.536 116.422L120.794 140.682L119.358 139.849L118.34 141.515H117.877V140.127L161.536 114.153L205.196 140.127Z"
          fill="#BAE4EF"
        />
        <path d="M207.557 307.913L190.566 317.821L207.372 307.82L207.557 307.913Z" fill="white" />
        <path
          d="M352.611 272.31L353.12 273.143L309.276 299.487L309.044 299.07V297.311L351.269 271.985L350.852 271.245L352.611 272.31Z"
          fill="#BAE4EF"
        />
        <path
          opacity="0.45"
          d="M350.852 271.245L351.269 271.986L309.044 297.311V277.495L330.712 265.596L321.961 253.744L350.852 271.245Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M309.044 201.611L350.852 271.244L321.961 253.743L309.044 236.289V201.611Z"
          fill="white"
        />
        <path d="M321.961 253.744L330.712 265.597L309.044 277.495V245.92L321.961 253.744Z" fill="white" />
        <path d="M309.044 236.289L321.961 253.743L309.044 245.919V236.289Z" fill="white" />
        <path
          d="M308.025 196.148L354 272.634L353.12 273.143L352.611 272.31L350.852 271.245L309.044 201.612V198.047H307.053L306.961 198L308.025 196.148Z"
          fill="#BAE4EF"
        />
        <path
          d="M309.044 277.495V297.31L308.025 297.912L307.053 297.31V277.772L307.748 278.189L309.044 277.495Z"
          fill="white"
        />
        <path d="M309.044 198.046V201.611L308.072 199.991L307.053 201.658V198.046H309.044Z" fill="white" />
        <path
          d="M309.044 236.289V245.919L308.025 245.363L307.053 245.965V236.705L308.211 235.177L309.044 236.289Z"
          fill="white"
        />
        <path
          d="M309.044 245.92V277.495L307.748 278.19L307.053 277.773V245.966L308.025 245.364L309.044 245.92Z"
          fill="white"
        />
        <path
          d="M308.211 235.177L307.053 236.705V201.657L308.072 199.99L309.044 201.611V236.288L308.211 235.177Z"
          fill="white"
        />
        <path d="M309.044 297.311V299.07H307.053V297.311L308.025 297.913L309.044 297.311Z" fill="white" />
        <path
          opacity="0.45"
          d="M307.053 277.773V297.311L265.106 272.264L265.569 271.523L294.321 253.791L285.524 265.597L307.053 277.773Z"
          fill="white"
        />
        <path d="M307.053 245.965V277.772L285.524 265.596L294.321 253.79L307.053 245.965Z" fill="white" />
        <path d="M307.053 236.705V245.965L294.321 253.79L307.053 236.705Z" fill="white" />
        <path opacity="0.3" d="M307.053 201.658V236.706L294.321 253.79L265.57 271.522L307.053 201.658Z" fill="white" />
        <path
          d="M307.053 198.046V201.657L265.57 271.522L263.764 272.633L262.931 272.124L306.961 198L307.053 198.046Z"
          fill="#BAE4EF"
        />
        <path
          d="M263.764 272.634L265.57 271.523L265.107 272.263L307.053 297.311V299.07H309.044L309.276 299.487L308.026 300.228L262.421 272.958L262.931 272.125L263.764 272.634Z"
          fill="#BAE4EF"
        />
        <path
          d="M234.087 291.894V342.082L190.566 369.213L146.536 342.915V291.94L147.045 291.663L190.195 266.522L234.087 291.894ZM232.096 340.971V293.052L190.195 268.791L148.48 293.052V341.804L190.519 366.898L232.096 340.971Z"
          fill="#BAE4EF"
        />
        <path
          opacity="0.3"
          d="M232.096 293.051V340.97L207.557 328.423V307.913L207.372 307.821L232.096 293.051Z"
          fill="white"
        />
        <path
          opacity="0.45"
          d="M232.096 340.97L190.566 366.898L190.195 338.609L207.557 328.423L232.096 340.97Z"
          fill="white"
        />
        <path opacity="0.3" d="M232.096 293.051L207.372 307.82L190.195 297.172V268.791L232.096 293.051Z" fill="white" />
        <path d="M207.557 307.913V328.423L190.195 338.609L190.566 317.821L207.557 307.913Z" fill="white" />
        <path d="M207.372 307.82L190.566 317.821L172.88 307.08L190.195 297.172L207.372 307.82Z" fill="white" />
        <path d="M205.196 141.516V190.777H204.224L203.251 190.175V142.395L204.733 141.516H205.196Z" fill="#BAE4EF" />
        <path
          d="M203.251 190.175V190.222L162.925 215.964L162.509 167.072L164.083 166.146L203.251 190.175Z"
          fill="white"
        />
        <path opacity="0.3" d="M203.251 142.395V190.176L164.083 166.147L203.251 142.395Z" fill="white" />
        <path
          d="M203.252 141.238V142.395L164.083 166.147L162.185 164.989L202.326 140.682L203.252 141.238Z"
          fill="white"
        />
        <path d="M190.566 317.821L190.195 338.609L172.88 328.006V307.079L190.566 317.821Z" fill="white" />
        <path
          opacity="0.45"
          d="M190.195 338.609L190.566 366.898L148.526 341.804L172.879 328.007L190.195 338.609Z"
          fill="white"
        />
        <path
          opacity="0.38"
          d="M190.195 268.791V297.172L172.879 307.08L148.526 293.051L190.195 268.791Z"
          fill="white"
        />
        <path opacity="0.3" d="M172.879 307.08V328.007L148.526 341.804V293.051L172.879 307.08Z" fill="white" />
        <path
          opacity="0.35"
          d="M161.536 165.359V116.422L202.326 140.682L162.185 164.989L161.536 165.359Z"
          fill="white"
        />
        <path
          d="M162.925 215.964L162.971 217.723L160.981 217.77V215.964L161.953 216.566L162.925 215.964Z"
          fill="#BAE4EF"
        />
        <path opacity="0.45" d="M161.536 116.422V165.359L120.793 140.682L161.536 116.422Z" fill="white" />
        <path
          d="M161.536 165.359L162.185 164.989L164.083 166.146L162.509 167.072L162.925 215.964L161.953 216.566L160.981 215.964L160.564 167.072L159.638 166.517L161.536 165.359Z"
          fill="white"
        />
        <path
          d="M161.536 165.359L159.638 166.517L119.867 142.442V141.238L120.793 140.682L161.536 165.359Z"
          fill="white"
        />
        <path
          d="M205.196 190.777V191.333L161.953 218.927L160.749 218.14L160.981 217.77L162.971 217.723L162.925 215.964L203.251 190.222V190.176L204.224 190.777H205.196Z"
          fill="#BAE4EF"
        />
        <path
          d="M160.564 167.073L160.981 215.964L120.053 190.222V190.176L159.638 166.517L160.564 167.073Z"
          fill="white"
        />
        <path opacity="0.3" d="M159.638 166.517L120.053 190.176L119.867 142.442L159.638 166.517Z" fill="white" />
        <path
          d="M120.053 190.176V190.222L160.981 215.964V217.77L160.749 218.14L118.108 191.333V190.777H119.08L120.053 190.176Z"
          fill="#BAE4EF"
        />
        <path
          d="M118.34 141.516L119.867 142.441L120.053 190.175L119.08 190.777H118.108L117.877 141.516H118.34Z"
          fill="#BAE4EF"
        />
        <path
          d="M91.5326 259.068L47.271 286.708L47.0396 286.338L46.9933 284.532L88.8473 258.42L88.2917 257.447L90.19 258.744L91.0233 258.188L91.5326 259.068Z"
          fill="#BAE4EF"
        />
        <path
          opacity="0.45"
          d="M88.2917 257.447L88.8473 258.419L46.9933 284.532L46.947 263.883L68.337 249.947L59.4476 238.511L88.2917 257.447Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M46.623 188.417L88.2918 257.448L59.4477 238.512L46.7619 222.215L46.623 188.417Z"
          fill="white"
        />
        <path d="M59.4476 238.511L68.3369 249.947L46.9469 263.883L46.808 230.177L59.4476 238.511Z" fill="white" />
        <path d="M46.7619 222.215L59.4477 238.512L46.8082 230.178L46.7619 222.215Z" fill="white" />
        <path
          d="M45.8358 264.577L46.9469 263.883L47.0395 284.578L46.0673 285.18C45.7432 284.995 45.4191 284.764 45.095 284.578L45.0024 264.114L45.8358 264.577Z"
          fill="white"
        />
        <path
          d="M46.7617 222.214L46.808 230.177L45.8357 229.576L44.8171 230.224L44.7708 222.399L45.8357 221.01L46.7617 222.214Z"
          fill="white"
        />
        <path
          d="M46.8082 230.178L46.9471 263.883L45.8359 264.578L44.9562 264.068L44.8173 230.224L45.8359 229.576L46.8082 230.178Z"
          fill="white"
        />
        <path
          d="M46.7153 184.758L91.0232 258.188L90.1898 258.744L88.2916 257.447L46.6227 188.416L46.5764 184.805L46.7153 184.758Z"
          fill="#BAE4EF"
        />
        <path
          d="M46.5766 184.805V188.37L45.558 186.703C45.2802 187.166 44.9561 187.721 44.5858 188.323V184.758L46.5766 184.805Z"
          fill="#BAE4EF"
        />
        <path
          d="M46.021 285.181L46.9932 284.579V286.338H45.0024V284.579C45.3728 284.764 45.6969 284.949 46.021 285.181Z"
          fill="#BAE4EF"
        />
        <path
          d="M46.6229 188.417L46.7618 222.215L45.8358 221.011L44.771 222.4L44.6321 188.37C45.0025 187.722 45.3266 187.166 45.6044 186.75L46.6229 188.417Z"
          fill="white"
        />
        <path
          opacity="0.45"
          d="M44.9562 264.068L45.0488 284.532C29.0757 274.717 6.75973 260.92 2.40765 258.05C2.63915 257.725 2.87064 257.355 3.19473 256.892L33.6593 237.354L23.2884 251.29L44.9562 264.068Z"
          fill="white"
        />
        <path d="M44.8173 230.224L44.9562 264.069L23.3347 251.29L33.6593 237.354L44.8173 230.224Z" fill="white" />
        <path d="M44.7709 222.399L44.8172 230.224L33.6592 237.354L44.7709 222.399Z" fill="white" />
        <path
          opacity="0.3"
          d="M44.632 188.37L44.7709 222.4L33.6592 237.354L3.1946 256.939C4.25947 255.225 6.06512 252.355 9.12083 247.355C12.8247 241.243 18.0102 232.771 24.3994 222.122C32.6869 208.279 41.067 194.343 44.632 188.37Z"
          fill="white"
        />
        <path
          d="M44.7708 184.342L45.6042 182.953L46.7153 184.805L46.5765 184.851H44.5856V188.416C41.0206 194.389 32.6405 208.325 24.3531 222.122C17.9175 232.771 12.7784 241.29 9.07448 247.355C6.01876 252.355 4.25941 255.226 3.14824 256.939L1.43519 258.05L0.972204 257.355H0.509216C3.42604 253.235 28.2422 211.983 44.7708 184.342Z"
          fill="#BAE4EF"
        />
        <path
          d="M47.2711 286.708L46.021 287.449L45.5118 287.125C45.3729 287.079 34.076 280.134 22.9643 273.235C16.4362 269.207 11.2044 265.966 7.4542 263.605C5.23186 262.216 3.51881 261.105 2.36134 260.364C-0.0461925 258.836 -0.185089 258.744 0.0464049 257.957L0.2316 257.262H0.509392H0.972379L1.43537 257.957L3.14842 256.846C2.82433 257.309 2.59283 257.725 2.36134 258.003C6.71342 260.874 29.0294 274.671 45.0025 284.486V286.245H46.9933L47.2711 286.708Z"
          fill="#BAE4EF"
        />
      </g>
      <path
        d="M27.5824 335.978C23.2527 333.403 23.3467 327.102 27.7513 324.657L165.674 248.093C167.558 247.047 169.837 246.998 171.764 247.962L326.136 325.148C330.616 327.388 331.01 333.63 326.846 336.415L189.767 428.117C187.674 429.517 184.962 429.589 182.798 428.302L27.5824 335.978Z"
        fill="url(#paint1_linear_172_124600)"
      />
      <path
        d="M31.1462 275.395C26.8165 272.819 26.9104 266.519 31.3151 264.074L169.238 187.51C171.122 186.464 173.401 186.415 175.328 187.379L329.699 264.564C334.18 266.805 334.573 273.047 330.41 275.832L193.331 367.534C191.238 368.934 188.526 369.006 186.362 367.719L31.1462 275.395Z"
        fill="url(#paint2_linear_172_124600)"
        fill-opacity="0.45"
      />
      <g clip-path="url(#clip1_172_124600)">
        <path
          d="M203.714 139.849L204.733 141.516L203.252 142.395V141.238L202.326 140.682L203.714 139.849Z"
          fill="#FFC8C8"
        />
        <path
          d="M119.867 141.238V142.442L118.34 141.516L119.358 139.849L120.793 140.682L119.867 141.238Z"
          fill="#FFC8C8"
        />
        <path
          d="M205.196 140.127V141.515H204.733L203.715 139.849L202.326 140.682L161.536 116.422L120.794 140.682L119.358 139.849L118.34 141.515H117.877V140.127L161.536 114.153L205.196 140.127Z"
          fill="#FFC8C8"
        />
        <path d="M207.557 307.913L190.566 317.821L207.372 307.82L207.557 307.913Z" fill="white" />
        <path
          d="M352.611 272.31L353.12 273.143L309.276 299.487L309.044 299.07V297.311L351.269 271.985L350.852 271.245L352.611 272.31Z"
          fill="#FFC8C8"
        />
        <path
          opacity="0.45"
          d="M350.852 271.245L351.269 271.986L309.044 297.311V277.495L330.712 265.596L321.961 253.744L350.852 271.245Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M309.044 201.611L350.852 271.244L321.961 253.743L309.044 236.289V201.611Z"
          fill="white"
        />
        <path d="M321.961 253.744L330.712 265.597L309.044 277.495V245.92L321.961 253.744Z" fill="#FF8282" />
        <path d="M309.044 236.289L321.961 253.743L309.044 245.919V236.289Z" fill="#FF8282" />
        <path
          d="M308.025 196.148L354 272.634L353.12 273.143L352.611 272.31L350.852 271.245L309.044 201.612V198.047H307.053L306.961 198L308.025 196.148Z"
          fill="#FFC8C8"
        />
        <path
          d="M309.044 277.495V297.31L308.025 297.912L307.053 297.31V277.772L307.748 278.189L309.044 277.495Z"
          fill="#FFC8C8"
        />
        <path d="M309.044 198.046V201.611L308.072 199.991L307.053 201.658V198.046H309.044Z" fill="#FFC8C8" />
        <path
          d="M309.044 236.289V245.919L308.025 245.363L307.053 245.965V236.705L308.211 235.177L309.044 236.289Z"
          fill="#FFC8C8"
        />
        <path
          d="M309.044 245.92V277.495L307.748 278.19L307.053 277.773V245.966L308.025 245.364L309.044 245.92Z"
          fill="#FFC8C8"
        />
        <path
          d="M308.211 235.177L307.053 236.705V201.657L308.072 199.99L309.044 201.611V236.288L308.211 235.177Z"
          fill="#FFC8C8"
        />
        <path d="M309.044 297.311V299.07H307.053V297.311L308.025 297.913L309.044 297.311Z" fill="#FFC8C8" />
        <path
          opacity="0.45"
          d="M307.053 277.773V297.311L265.106 272.264L265.569 271.523L294.321 253.791L285.524 265.597L307.053 277.773Z"
          fill="white"
        />
        <path d="M307.053 245.965V277.772L285.524 265.596L294.321 253.79L307.053 245.965Z" fill="#FF5C5C" />
        <path d="M307.053 236.705V245.965L294.321 253.79L307.053 236.705Z" fill="#FF5C5C" />
        <path opacity="0.3" d="M307.053 201.658V236.706L294.321 253.79L265.57 271.522L307.053 201.658Z" fill="white" />
        <path
          d="M307.053 198.046V201.657L265.57 271.522L263.764 272.633L262.931 272.124L306.961 198L307.053 198.046Z"
          fill="#FFC8C8"
        />
        <path
          d="M263.764 272.634L265.57 271.523L265.107 272.263L307.053 297.311V299.07H309.044L309.276 299.487L308.026 300.228L262.421 272.958L262.931 272.125L263.764 272.634Z"
          fill="#FFC8C8"
        />
        <path
          d="M234.087 291.894V342.082L190.566 369.213L146.536 342.915V291.94L147.045 291.663L190.195 266.522L234.087 291.894ZM232.096 340.971V293.052L190.195 268.791L148.48 293.052V341.804L190.519 366.898L232.096 340.971Z"
          fill="#FFC8C8"
        />
        <path
          opacity="0.3"
          d="M232.096 293.051V340.97L207.557 328.423V307.913L207.372 307.821L232.096 293.051Z"
          fill="white"
        />
        <path
          opacity="0.45"
          d="M232.096 340.97L190.566 366.898L190.195 338.609L207.557 328.423L232.096 340.97Z"
          fill="white"
        />
        <path opacity="0.3" d="M232.096 293.051L207.372 307.82L190.195 297.172V268.791L232.096 293.051Z" fill="white" />
        <path d="M207.557 307.913V328.423L190.195 338.609L190.566 317.821L207.557 307.913Z" fill="white" />
        <path d="M207.372 307.82L190.566 317.821L172.88 307.08L190.195 297.172L207.372 307.82Z" fill="white" />
        <path d="M205.196 141.516V190.777H204.224L203.251 190.175V142.395L204.733 141.516H205.196Z" fill="#FFC8C8" />
        <path
          d="M203.251 190.175V190.222L162.925 215.964L162.509 167.072L164.083 166.146L203.251 190.175Z"
          fill="#E0AAAA"
        />
        <path opacity="0.3" d="M203.251 142.395V190.176L164.083 166.147L203.251 142.395Z" fill="white" />
        <path
          d="M203.252 141.238V142.395L164.083 166.147L162.185 164.989L202.326 140.682L203.252 141.238Z"
          fill="#FFC8C8"
        />
        <path d="M190.566 317.821L190.195 338.609L172.88 328.006V307.079L190.566 317.821Z" fill="white" />
        <path
          opacity="0.45"
          d="M190.195 338.609L190.566 366.898L148.526 341.804L172.879 328.007L190.195 338.609Z"
          fill="white"
        />
        <path
          opacity="0.38"
          d="M190.195 268.791V297.172L172.879 307.08L148.526 293.051L190.195 268.791Z"
          fill="white"
        />
        <path opacity="0.3" d="M172.879 307.08V328.007L148.526 341.804V293.051L172.879 307.08Z" fill="white" />
        <path
          opacity="0.35"
          d="M161.536 165.359V116.422L202.326 140.682L162.185 164.989L161.536 165.359Z"
          fill="white"
        />
        <path
          d="M162.925 215.964L162.971 217.723L160.981 217.77V215.964L161.953 216.566L162.925 215.964Z"
          fill="#FFC8C8"
        />
        <path opacity="0.45" d="M161.536 116.422V165.359L120.793 140.682L161.536 116.422Z" fill="white" />
        <path
          d="M161.536 165.359L162.185 164.989L164.083 166.146L162.509 167.072L162.925 215.964L161.953 216.566L160.981 215.964L160.564 167.072L159.638 166.517L161.536 165.359Z"
          fill="#FFC8C8"
        />
        <path
          d="M161.536 165.359L159.638 166.517L119.867 142.442V141.238L120.793 140.682L161.536 165.359Z"
          fill="#FFC8C8"
        />
        <path
          d="M205.196 190.777V191.333L161.953 218.927L160.749 218.14L160.981 217.77L162.971 217.723L162.925 215.964L203.251 190.222V190.176L204.224 190.777H205.196Z"
          fill="#FFC8C8"
        />
        <path
          d="M160.564 167.073L160.981 215.964L120.053 190.222V190.176L159.638 166.517L160.564 167.073Z"
          fill="#E0AAAA"
        />
        <path opacity="0.3" d="M159.638 166.517L120.053 190.176L119.867 142.442L159.638 166.517Z" fill="white" />
        <path
          d="M120.053 190.176V190.222L160.981 215.964V217.77L160.749 218.14L118.108 191.333V190.777H119.08L120.053 190.176Z"
          fill="#FFC8C8"
        />
        <path
          d="M118.34 141.516L119.867 142.441L120.053 190.175L119.08 190.777H118.108L117.877 141.516H118.34Z"
          fill="#FFC8C8"
        />
        <path
          d="M91.5326 259.068L47.271 286.708L47.0396 286.338L46.9933 284.532L88.8473 258.42L88.2917 257.447L90.19 258.744L91.0233 258.188L91.5326 259.068Z"
          fill="#FFC8C8"
        />
        <path
          opacity="0.45"
          d="M88.2917 257.447L88.8473 258.419L46.9933 284.532L46.947 263.883L68.337 249.947L59.4476 238.511L88.2917 257.447Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M46.623 188.417L88.2918 257.448L59.4477 238.512L46.7619 222.215L46.623 188.417Z"
          fill="white"
        />
        <path d="M59.4476 238.511L68.3369 249.947L46.9469 263.883L46.808 230.177L59.4476 238.511Z" fill="#FF8282" />
        <path d="M46.7619 222.215L59.4477 238.512L46.8082 230.178L46.7619 222.215Z" fill="#FF8282" />
        <path
          d="M45.8358 264.577L46.9469 263.883L47.0395 284.578L46.0673 285.18C45.7432 284.995 45.4191 284.764 45.095 284.578L45.0024 264.114L45.8358 264.577Z"
          fill="#FFC8C8"
        />
        <path
          d="M46.7617 222.214L46.808 230.177L45.8357 229.576L44.8171 230.224L44.7708 222.399L45.8357 221.01L46.7617 222.214Z"
          fill="#FFC8C8"
        />
        <path
          d="M46.8082 230.178L46.9471 263.883L45.8359 264.578L44.9562 264.068L44.8173 230.224L45.8359 229.576L46.8082 230.178Z"
          fill="#FFC8C8"
        />
        <path
          d="M46.7153 184.758L91.0232 258.188L90.1898 258.744L88.2916 257.447L46.6227 188.416L46.5764 184.805L46.7153 184.758Z"
          fill="#FFC8C8"
        />
        <path
          d="M46.5766 184.805V188.37L45.558 186.703C45.2802 187.166 44.9561 187.721 44.5858 188.323V184.758L46.5766 184.805Z"
          fill="#FFC8C8"
        />
        <path
          d="M46.021 285.181L46.9932 284.579V286.338H45.0024V284.579C45.3728 284.764 45.6969 284.949 46.021 285.181Z"
          fill="#FFC8C8"
        />
        <path
          d="M46.6229 188.417L46.7618 222.215L45.8358 221.011L44.771 222.4L44.6321 188.37C45.0025 187.722 45.3266 187.166 45.6044 186.75L46.6229 188.417Z"
          fill="#FFC8C8"
        />
        <path
          opacity="0.45"
          d="M44.9562 264.068L45.0488 284.532C29.0757 274.717 6.75973 260.92 2.40765 258.05C2.63915 257.725 2.87064 257.355 3.19473 256.892L33.6593 237.354L23.2884 251.29L44.9562 264.068Z"
          fill="white"
        />
        <path d="M44.8173 230.224L44.9562 264.069L23.3347 251.29L33.6593 237.354L44.8173 230.224Z" fill="#FF5C5C" />
        <path d="M44.7709 222.399L44.8172 230.224L33.6592 237.354L44.7709 222.399Z" fill="#FF5C5C" />
        <path
          opacity="0.3"
          d="M44.632 188.37L44.7709 222.4L33.6592 237.354L3.1946 256.939C4.25947 255.225 6.06512 252.355 9.12083 247.355C12.8247 241.243 18.0102 232.771 24.3994 222.122C32.6869 208.279 41.067 194.343 44.632 188.37Z"
          fill="white"
        />
        <path
          d="M44.7708 184.342L45.6042 182.953L46.7153 184.805L46.5765 184.851H44.5856V188.416C41.0206 194.389 32.6405 208.325 24.3531 222.122C17.9175 232.771 12.7784 241.29 9.07448 247.355C6.01876 252.355 4.25941 255.226 3.14824 256.939L1.43519 258.05L0.972204 257.355H0.509216C3.42604 253.235 28.2422 211.983 44.7708 184.342Z"
          fill="#FFC8C8"
        />
        <path
          d="M47.2711 286.708L46.021 287.449L45.5118 287.125C45.3729 287.079 34.076 280.134 22.9643 273.235C16.4362 269.207 11.2044 265.966 7.4542 263.605C5.23186 262.216 3.51881 261.105 2.36134 260.364C-0.0461925 258.836 -0.185089 258.744 0.0464049 257.957L0.2316 257.262H0.509392H0.972379L1.43537 257.957L3.14842 256.846C2.82433 257.309 2.59283 257.725 2.36134 258.003C6.71342 260.874 29.0294 274.671 45.0025 284.486V286.245H46.9933L47.2711 286.708Z"
          fill="#FFC8C8"
        />
        <path d="M68.5463 342.543L82.8932 351.311V369.643L68.5463 360.875V342.543Z" fill="#FF5C5C" />
        <path d="M97.2401 342.543L82.8932 351.311V369.643L97.2401 360.875V342.543Z" fill="#FF8282" />
        <path d="M82.8932 334.573L97.2401 342.543L82.8932 351.311L68.5463 342.543L82.8932 334.573Z" fill="#FFF2F2" />
        <g filter="url(#filter0_f_172_124600)">
          <path d="M30.2879 297.908L38.2584 302.779V312.964L30.2879 308.093V297.908Z" fill="#FF5C5C" />
          <path d="M46.2289 297.908L38.2584 302.779V312.964L46.2289 308.093V297.908Z" fill="#FF8282" />
          <path d="M38.2584 293.48L46.2289 297.908L38.2584 302.779L30.2879 297.908L38.2584 293.48Z" fill="#FFF2F2" />
        </g>
        <path d="M253.952 350.82L262.23 355.879V366.455L253.952 361.397V350.82Z" fill="#FF5C5C" />
        <path d="M270.507 350.82L262.23 355.879V366.455L270.507 361.397V350.82Z" fill="#FF8282" />
        <path d="M262.23 346.222L270.507 350.82L262.23 355.879L253.953 350.82L262.23 346.222Z" fill="#FFF2F2" />
        <g filter="url(#filter1_f_172_124600)">
          <path d="M315.632 322.126L323.909 327.185V337.761L315.632 332.703V322.126Z" fill="#FF5C5C" />
          <path d="M332.186 322.126L323.909 327.185V337.761L332.186 332.703V322.126Z" fill="#FF8282" />
          <path d="M323.909 317.528L332.186 322.127L323.909 327.185L315.632 322.127L323.909 317.528Z" fill="#FFF2F2" />
        </g>
        <g filter="url(#filter2_f_172_124600)">
          <path d="M239.115 320.226L242.303 322.174V326.248L239.115 324.299V320.226Z" fill="#FF5C5C" />
          <path d="M245.492 320.226L242.303 322.174V326.248L245.492 324.299V320.226Z" fill="#FF8282" />
          <path d="M242.303 318.454L245.492 320.226L242.303 322.174L239.115 320.226L242.303 318.454Z" fill="#FFF2F2" />
        </g>
        <g filter="url(#filter3_f_172_124600)">
          <path d="M216.798 291.532L223.174 295.428V303.576L216.798 299.679V291.532Z" fill="#FF5C5C" />
          <path d="M229.551 291.532L223.174 295.428V303.576L229.551 299.679V291.532Z" fill="#FF8282" />
          <path d="M223.174 287.989L229.551 291.532L223.174 295.428L216.798 291.532L223.174 287.989Z" fill="#FFF2F2" />
        </g>
        <g filter="url(#filter4_f_172_124600)">
          <path d="M122.746 286.749L129.122 290.646V298.794L122.746 294.897V286.749Z" fill="#FF5C5C" />
          <path d="M135.499 286.749L129.122 290.646V298.794L135.499 294.897V286.749Z" fill="#FF8282" />
          <path d="M129.122 283.207L135.499 286.749L129.122 290.646L122.746 286.749L129.122 283.207Z" fill="#FFF2F2" />
        </g>
        <g filter="url(#filter5_f_172_124600)">
          <path d="M151.44 265.597L154.199 267.283V270.809L151.44 269.123V265.597Z" fill="#FF5C5C" />
          <path d="M156.958 265.597L154.199 267.283V270.809L156.958 269.123V265.597Z" fill="#FF8282" />
          <path d="M154.199 264.064L156.958 265.597L154.199 267.283L151.439 265.597L154.199 264.064Z" fill="#FFF2F2" />
        </g>
        <g filter="url(#filter6_f_172_124600)">
          <path d="M149.846 320.846L153.831 323.282V328.374L149.846 325.938V320.846Z" fill="#FF5C5C" />
          <path d="M157.816 320.846L153.831 323.282V328.374L157.816 325.938V320.846Z" fill="#FF8282" />
          <path d="M153.831 318.632L157.816 320.846L153.831 323.281L149.846 320.846L153.831 318.632Z" fill="#FFF2F2" />
        </g>
        <g filter="url(#filter7_f_172_124600)">
          <path d="M247.086 181.541L255.056 186.412V196.596L247.086 191.725V181.541Z" fill="#FF5C5C" />
          <path d="M263.027 181.541L255.056 186.412V196.596L263.027 191.725V181.541Z" fill="#FF8282" />
          <path d="M255.056 177.111L263.027 181.539L255.056 186.41L247.086 181.539L255.056 177.111Z" fill="#FFF2F2" />
        </g>
        <g filter="url(#filter8_f_172_124600)">
          <path d="M58.9818 145.674L69.3435 152.006V165.246L58.9818 158.914V145.674Z" fill="#FF5C5C" />
          <path d="M79.7051 145.674L69.3435 152.006V165.246L79.7051 158.914V145.674Z" fill="#FF8282" />
          <path d="M69.3435 139.916L79.7051 145.672L69.3435 152.004L58.9818 145.672L69.3435 139.916Z" fill="#FFF2F2" />
        </g>
      </g>
      <g filter="url(#filter9_f_172_124600)">
        <path d="M278.968 10.9723L286.938 15.8431V26.0276L278.968 21.1568V10.9723Z" fill="#FF5C5C" />
        <path d="M294.909 10.9723L286.938 15.8431V26.0276L294.909 21.1568V10.9723Z" fill="#FF8282" />
        <path d="M286.938 6.54268L294.909 10.9707L286.938 15.8416L278.968 10.9707L286.938 6.54268Z" fill="#FFF2F2" />
      </g>
      <path d="M137.093 39.6643L151.44 48.4319V66.764L137.093 57.9965V39.6643Z" fill="#FF5C5C" />
      <path d="M165.787 39.6643L151.44 48.4319V66.764L165.787 57.9965V39.6643Z" fill="#FF8282" />
      <path d="M151.44 31.6938L165.787 39.6643L151.44 48.4318L137.093 39.6643L151.44 31.6938Z" fill="#FFF2F2" />
      <path d="M250.274 87.4873L264.621 96.2549V114.587L250.274 105.819V87.4873Z" fill="#FF5C5C" />
      <path d="M278.968 87.4873L264.621 96.2549V114.587L278.968 105.819V87.4873Z" fill="#FF8282" />
      <path d="M264.621 79.5169L278.968 87.4874L264.621 96.255L250.274 87.4874L264.621 79.5169Z" fill="#FFF2F2" />
      <path d="M181.727 383.99L196.074 392.758V411.09L181.727 402.322V383.99Z" fill="#FF5C5C" />
      <path d="M210.421 383.99L196.074 392.758V411.09L210.421 402.322V383.99Z" fill="#FF8282" />
      <path d="M196.074 376.02L210.421 383.99L196.074 392.758L181.727 383.99L196.074 376.02Z" fill="#FFF2F2" />
      <defs>
        <filter
          id="filter0_f_172_124600"
          x="23.9115"
          y="287.104"
          width="28.6938"
          height="32.2362"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="3.1882" result="effect1_foregroundBlur_172_124600" />
        </filter>
        <filter
          id="filter1_f_172_124600"
          x="309.256"
          y="311.152"
          width="29.307"
          height="32.9855"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="3.1882" result="effect1_foregroundBlur_172_124600" />
        </filter>
        <filter
          id="filter2_f_172_124600"
          x="236.659"
          y="315.998"
          width="11.2886"
          height="12.7056"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="1.22805" result="effect1_foregroundBlur_172_124600" />
        </filter>
        <filter
          id="filter3_f_172_124600"
          x="211.886"
          y="283.077"
          width="22.5772"
          height="25.4112"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.4561" result="effect1_foregroundBlur_172_124600" />
        </filter>
        <filter
          id="filter4_f_172_124600"
          x="117.834"
          y="278.295"
          width="22.5772"
          height="25.4111"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.4561" result="effect1_foregroundBlur_172_124600" />
        </filter>
        <filter
          id="filter5_f_172_124600"
          x="149.314"
          y="261.939"
          width="9.76909"
          height="10.9951"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="1.06273" result="effect1_foregroundBlur_172_124600" />
        </filter>
        <filter
          id="filter6_f_172_124600"
          x="146.775"
          y="315.562"
          width="14.1108"
          height="15.8823"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="1.53506" result="effect1_foregroundBlur_172_124600" />
        </filter>
        <filter
          id="filter7_f_172_124600"
          x="240.945"
          y="170.971"
          width="28.2215"
          height="31.7653"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="3.07011" result="effect1_foregroundBlur_172_124600" />
        </filter>
        <filter
          id="filter8_f_172_124600"
          x="50.9995"
          y="131.933"
          width="36.688"
          height="41.2952"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="3.99116" result="effect1_foregroundBlur_172_124600" />
        </filter>
        <filter
          id="filter9_f_172_124600"
          x="272.827"
          y="0.402501"
          width="28.2214"
          height="31.7653"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="3.07011" result="effect1_foregroundBlur_172_124600" />
        </filter>
        <linearGradient
          id="paint0_linear_172_124600"
          x1="17.8188"
          y1="310.159"
          x2="234.614"
          y2="430.733"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6D0000" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_172_124600"
          x1="17.8188"
          y1="310.159"
          x2="234.614"
          y2="430.733"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A50202" />
          <stop offset="1" stop-color="#AD0303" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_172_124600"
          x1="97.1555"
          y1="190.088"
          x2="263.972"
          y2="349.086"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="0.078125" stop-color="#DB0101" />
        </linearGradient>
        <clipPath id="clip0_172_124600">
          <rect width="354" height="255.06" fill="white" transform="translate(0 114.153)" />
        </clipPath>
        <clipPath id="clip1_172_124600">
          <rect width="354" height="255.06" fill="white" transform="translate(0 114.153)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
