import React from "react";
import { SvgProps, Svg } from "@raca2022/uikit";

const LogoRaca: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 22 22" fill="none" {...props}>
      <path
        d="M0.582275 10.1245C0.582839 12.5205 1.43275 14.8378 2.97924 16.6597C4.52573 18.4817 6.66739 19.6889 9.01928 20.0645V13.0324H6.48228V10.1245H9.02228V7.91096C8.96619 7.39288 9.02417 6.86872 9.1921 6.37572C9.36002 5.88272 9.63378 5.43297 9.99392 5.05841C10.3541 4.68385 10.7917 4.39368 11.2759 4.20849C11.7601 4.02331 12.2788 3.94766 12.7953 3.98691C13.5453 3.99899 14.2953 4.0664 15.0353 4.18815V6.66231H13.7713C13.5564 6.634 13.338 6.65462 13.1322 6.72266C12.9264 6.7907 12.7383 6.90441 12.582 7.05539C12.4258 7.20636 12.3052 7.39075 12.2292 7.59492C12.1533 7.79909 12.1239 8.01782 12.1433 8.23495V10.1245H14.9143L14.4713 13.0334H12.1433V20.0645C14.0309 19.7643 15.7935 18.9262 17.2224 17.6494C18.6514 16.3726 19.6868 14.7106 20.206 12.8601C20.7253 11.0096 20.7067 9.04818 20.1525 7.20799C19.5982 5.3678 18.5315 3.72594 17.0786 2.4768C15.6258 1.22766 13.8476 0.423542 11.9546 0.159659C10.0617 -0.104223 8.1332 0.183177 6.39753 0.987834C4.66186 1.79249 3.19169 3.08071 2.16108 4.69996C1.13047 6.31922 0.582579 8.2017 0.582275 10.1245Z"
        fill="#EA6E6E"
      />
    </Svg>
  );
};

export default LogoRaca;
