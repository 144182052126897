import React, { useEffect } from "react";

const useLogout = () => {
  const isConnectVeri = window.localStorage.getItem("accountStatus");

  const isLogout = (isConnectVeri: any) => {
    return isConnectVeri === null;
  };

  useEffect(() => {
    (async () => {
      if (isLogout(isConnectVeri)) {
        localStorage.removeItem("jwt");
        localStorage.removeItem("isCheck");
      }
    })();
  }, [isConnectVeri]);
};

export default useLogout;
