import React from "react";
import { SvgProps, Svg } from "@raca2022/uikit";

const LogoRaca: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 211 69" fill="none" {...props}>
      <path
        d="M19.7793 20.8247L19.7793 50.4122L40.1726 67.3633"
        stroke="url(#paint0_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M1.99999 49.5854L2 19.998L22.3933 3.04688"
        stroke="url(#paint1_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M37.0352 49.5854L37.0352 19.998L57.4284 3.04688"
        stroke="url(#paint2_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M37.0351 49.062L37.0352 19.4746L16.6419 2.52343"
        stroke="url(#paint3_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M72.0693 49.062L72.0693 19.4746L51.676 2.52343"
        stroke="url(#paint4_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M54.8135 20.3013L54.8135 49.8887L34.4202 66.8398"
        stroke="url(#paint5_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path d="M54.29 48.5385L54.29 18.9511L33.8967 2" stroke="url(#paint6_linear_30_26091)" stroke-width="3.66033" />
      <path
        d="M72.0693 19.7779L72.0693 49.3653L51.676 66.3164"
        stroke="url(#paint7_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M37.0352 19.7779L37.0352 49.3653L16.6419 66.3164"
        stroke="url(#paint8_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M37.0352 20.3013L37.0352 49.8887L57.4284 66.8398"
        stroke="url(#paint9_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M2.00001 20.3013L2 49.8887L22.3933 66.8398"
        stroke="url(#paint10_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M19.2559 49.062L19.2559 19.4746L39.6492 2.52344"
        stroke="url(#paint11_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M87.8486 20.8247L87.8486 50.4122L108.242 67.3633"
        stroke="url(#paint12_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M70.0693 49.5854L70.0693 19.998L90.4626 3.04688"
        stroke="url(#paint13_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M105.104 49.5854L105.104 19.998L125.498 3.04688"
        stroke="url(#paint14_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M105.104 49.062L105.104 19.4746L84.7112 2.52343"
        stroke="url(#paint15_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M140.139 49.062L140.139 19.4746L119.745 2.52343"
        stroke="url(#paint16_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M122.883 20.3013L122.883 49.8887L102.49 66.8398"
        stroke="url(#paint17_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M122.359 48.5385L122.359 18.9511L101.966 2"
        stroke="url(#paint18_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M140.139 19.7779L140.139 49.3653L119.745 66.3164"
        stroke="url(#paint19_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M105.104 19.7779L105.104 49.3653L84.7112 66.3164"
        stroke="url(#paint20_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M105.105 20.3013L105.104 49.8887L125.498 66.8398"
        stroke="url(#paint21_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M70.0693 20.3013L70.0693 49.8887L90.4626 66.8398"
        stroke="url(#paint22_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M87.3252 49.062L87.3252 19.4746L107.718 2.52344"
        stroke="url(#paint23_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M155.918 20.8247L155.918 50.4122L176.311 67.3633"
        stroke="url(#paint24_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M138.139 49.5854L138.139 19.998L158.532 3.04688"
        stroke="url(#paint25_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M173.174 49.5854L173.174 19.998L193.567 3.04688"
        stroke="url(#paint26_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M173.174 49.062L173.174 19.4746L152.781 2.52343"
        stroke="url(#paint27_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M208.208 49.062L208.208 19.4746L187.815 2.52343"
        stroke="url(#paint28_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M190.952 20.3013L190.952 49.8887L170.559 66.8398"
        stroke="url(#paint29_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M190.429 48.5385L190.429 18.9511L170.035 2"
        stroke="url(#paint30_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M208.208 19.7779L208.208 49.3653L187.815 66.3164"
        stroke="url(#paint31_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M173.174 19.7779L173.174 49.3653L152.781 66.3164"
        stroke="url(#paint32_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M173.174 20.3013L173.174 49.8887L193.567 66.8398"
        stroke="url(#paint33_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M138.139 20.3013L138.139 49.8887L158.532 66.8398"
        stroke="url(#paint34_linear_30_26091)"
        stroke-width="3.66033"
      />
      <path
        d="M155.395 49.062L155.395 19.4746L175.788 2.52344"
        stroke="url(#paint35_linear_30_26091)"
        stroke-width="3.66033"
      />
      <defs>
        <linearGradient
          id="paint0_linear_30_26091"
          x1="19.7793"
          y1="65.1357"
          x2="20.7591"
          y2="26.6575"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_30_26091"
          x1="2.00002"
          y1="5.27446"
          x2="2.97978"
          y2="43.7527"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_30_26091"
          x1="37.0352"
          y1="5.27446"
          x2="38.0149"
          y2="43.7527"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_30_26091"
          x1="37.0351"
          y1="4.75103"
          x2="36.0554"
          y2="43.2292"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_30_26091"
          x1="72.0693"
          y1="4.75103"
          x2="71.0895"
          y2="43.2292"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_30_26091"
          x1="54.8135"
          y1="64.6123"
          x2="53.8337"
          y2="26.134"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_30_26091"
          x1="54.29"
          y1="4.22759"
          x2="53.3102"
          y2="42.7058"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_30_26091"
          x1="72.0693"
          y1="64.0888"
          x2="71.0896"
          y2="25.6106"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_30_26091"
          x1="37.0351"
          y1="64.0888"
          x2="36.0554"
          y2="25.6106"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_30_26091"
          x1="37.0352"
          y1="64.6123"
          x2="38.0149"
          y2="26.134"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_30_26091"
          x1="2.00002"
          y1="64.6123"
          x2="2.97979"
          y2="26.134"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_30_26091"
          x1="19.2559"
          y1="4.75103"
          x2="20.2356"
          y2="43.2292"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_30_26091"
          x1="87.8487"
          y1="65.1357"
          x2="88.8284"
          y2="26.6575"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_30_26091"
          x1="70.0694"
          y1="5.27446"
          x2="71.0491"
          y2="43.7527"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_30_26091"
          x1="105.105"
          y1="5.27446"
          x2="106.084"
          y2="43.7527"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_30_26091"
          x1="105.104"
          y1="4.75103"
          x2="104.125"
          y2="43.2292"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_30_26091"
          x1="140.139"
          y1="4.75103"
          x2="139.159"
          y2="43.2292"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_30_26091"
          x1="122.883"
          y1="64.6123"
          x2="121.903"
          y2="26.134"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_30_26091"
          x1="122.359"
          y1="4.22759"
          x2="121.38"
          y2="42.7058"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_30_26091"
          x1="140.139"
          y1="64.0888"
          x2="139.159"
          y2="25.6106"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_30_26091"
          x1="105.104"
          y1="64.0888"
          x2="104.125"
          y2="25.6106"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_30_26091"
          x1="105.105"
          y1="64.6123"
          x2="106.084"
          y2="26.134"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_30_26091"
          x1="70.0694"
          y1="64.6123"
          x2="71.0491"
          y2="26.134"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_30_26091"
          x1="87.3252"
          y1="4.75103"
          x2="88.305"
          y2="43.2292"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_30_26091"
          x1="155.918"
          y1="65.1357"
          x2="156.898"
          y2="26.6575"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_30_26091"
          x1="138.139"
          y1="5.27446"
          x2="139.118"
          y2="43.7527"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_30_26091"
          x1="173.174"
          y1="5.27446"
          x2="174.154"
          y2="43.7527"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_30_26091"
          x1="173.174"
          y1="4.75103"
          x2="172.194"
          y2="43.2292"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_30_26091"
          x1="208.208"
          y1="4.75103"
          x2="207.228"
          y2="43.2292"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_30_26091"
          x1="190.952"
          y1="64.6123"
          x2="189.972"
          y2="26.134"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_30_26091"
          x1="190.429"
          y1="4.22759"
          x2="189.449"
          y2="42.7058"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_30_26091"
          x1="208.208"
          y1="64.0888"
          x2="207.228"
          y2="25.6106"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_30_26091"
          x1="173.174"
          y1="64.0888"
          x2="172.194"
          y2="25.6106"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_30_26091"
          x1="173.174"
          y1="64.6123"
          x2="174.154"
          y2="26.134"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_30_26091"
          x1="138.139"
          y1="64.6123"
          x2="139.118"
          y2="26.134"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_30_26091"
          x1="155.395"
          y1="4.75103"
          x2="156.374"
          y2="43.2292"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.536458" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default LogoRaca;
