export const ArrClone = [
  // {
  //   title: "Ecosystem",
  //   href: "/ecosystem",
  // },
  {
    title: "Dashboard",
    href: "/dashboard",
  },
  {
    title: "Investment",
    href: "/investment",
  },
  {
    title: "History",
    href: "/transaction",
  },
  {
    title: "Affiliates",
    href: "/affiliates",
  },
  // {
  //   title: "Swap",
  //   href: "/swap",
  // },
  {
    title: "Withdraw",
    href: "/withdraw",
  },
];
