import React from "react";
import { SvgProps, Svg } from "@raca2022/uikit";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 80 80" fill="#fff" {...props}>
      <rect width="80" height="80" rx="40" fill="url(#paint0_linear_95_76439)" fill-opacity="0.2" />
      <path
        d="M50.3127 30.5493C56.006 36.2426 56.006 45.4826 50.3127 51.176C44.6193 56.8693 35.3793 56.8693 29.686 51.176C23.9927 45.4826 23.9927 36.2426 29.686 30.5493C35.3793 24.856 44.6193 24.856 50.3127 30.5493Z"
        fill="url(#paint1_linear_95_76439)"
      />
      <path
        d="M31.2504 64.2433C31.0404 64.2433 30.8071 64.1967 30.5971 64.1267C25.3471 62.0267 21.1004 58.3167 18.2537 53.4167C15.5004 48.6333 14.4037 43.22 15.1271 37.7133C15.2437 36.7567 16.1537 36.08 17.0871 36.1967C18.0437 36.3133 18.7204 37.2 18.6037 38.1567C17.9971 42.8933 18.9304 47.56 21.2871 51.6667C23.7137 55.8667 27.3771 59.0633 31.8804 60.86C32.7671 61.2333 33.2104 62.2367 32.8604 63.1467C32.6037 63.8233 31.9271 64.2433 31.2504 64.2433Z"
        fill="url(#paint2_linear_95_76439)"
      />
      <path
        d="M25.6491 24.2025C25.1358 24.2025 24.6224 23.9692 24.2724 23.5259C23.6658 22.7792 23.8058 21.6825 24.5758 21.0759C29.0091 17.5992 34.3524 15.7559 39.9991 15.7559C45.5058 15.7559 50.7558 17.5292 55.1658 20.8892C55.9358 21.4725 56.0758 22.5692 55.4924 23.3392C54.9091 24.1092 53.8124 24.2492 53.0424 23.6659C49.2391 20.7725 44.7358 19.2559 39.9991 19.2559C35.1458 19.2559 30.5491 20.8425 26.7224 23.8292C26.3958 24.0859 26.0224 24.2025 25.6491 24.2025Z"
        fill="url(#paint3_linear_95_76439)"
      />
      <path
        d="M48.7503 64.2434C48.0503 64.2434 47.397 63.8234 47.117 63.1468C46.767 62.2601 47.187 61.2334 48.097 60.8601C52.6003 59.0401 56.2636 55.8668 58.6903 51.6668C61.0703 47.5601 62.0036 42.8934 61.3736 38.1801C61.257 37.2234 61.9336 36.3368 62.8903 36.2201C63.8236 36.1034 64.7336 36.7801 64.8503 37.7368C65.5503 43.2201 64.477 48.6568 61.7236 53.4401C58.9003 58.3401 54.6303 62.0268 49.3803 64.1501C49.1936 64.1968 48.9836 64.2434 48.7503 64.2434Z"
        fill="url(#paint4_linear_95_76439)"
      />
      <defs>
        <linearGradient id="paint0_linear_95_76439" x1="40" y1="0" x2="40" y2="88.5" gradientUnits="userSpaceOnUse">
          <stop stop-color="#CECECF" />
          <stop offset="1" stop-color="#070707" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_95_76439"
          x1="30.7373"
          y1="40.8627"
          x2="49.4753"
          y2="40.8686"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C80E0E" />
          <stop offset="1" stop-color="#AE1717" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_95_76439"
          x1="18.2069"
          y1="50.2136"
          x2="29.816"
          y2="50.2159"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C80E0E" />
          <stop offset="1" stop-color="#AE1717" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_95_76439"
          x1="29.7249"
          y1="19.9792"
          x2="50.2555"
          y2="20.0036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C80E0E" />
          <stop offset="1" stop-color="#AE1717" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_95_76439"
          x1="50.2898"
          y1="50.2253"
          x2="61.895"
          y2="50.2277"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C80E0E" />
          <stop offset="1" stop-color="#AE1717" />
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default Icon;
