// start Login ==========>
export const LOGIN_REQUEST = "@user/LOGIN_REQUEST";
export const LOGIN_SUCCESS = "@user/LOGIN_SUCCESS";
export const LOGIN_FAILED = "@user/LOGIN_FAILED";
// end Login <==========

// start dashboard =========>
export const LIST_USER_REQUEST = "@user/LIST_USER_REQUEST";
export const LIST_USER_SUCCESS = "@user/LIST_USER_SUCCESS";
export const LIST_USER_FAILED = "@user/LIST_USER_FAILED";
// end dashboard <===========

// start wallet =========>
export const LIST_WALLET_REQUEST = "@user/LIST_WALLET_REQUEST";
export const LIST_WALLET_SUCCESS = "@user/LIST_WALLET_SUCCESS";
export const LIST_ALLET_FAILED = "@user/LIST_ALLET_FAILED";
// end wallet <===========

// start history =========>
export const LICHSUDAUTU_SUCCESS = "@user/LICHSUDAUTU_SUCCESS";
export const LICHSUDAUTU_REQUEST = "@user/LICHSUDAUTU_REQUEST";
// end history  <===========

// start Affiliates get =========>
export const LIST_AFFILIATED_SUCCESS = "@user/LIST_AFFILIATED_SUCCESS";
// end Affiliates get <===========

//start swap
export const SWAP_SUCCESS = "@user/SWAP_SUCCESS";
//end swap

// start withdraw
// export const LICHSUGIAODICH_SUCCESS = "@user/LICHSUGIAODICH_SUCCESS";
export const WITHDRAW_SUCCESS = "@user/WITHDRAW_SUCCESS";
// end withdraw

// ProductSanPham
export const PRODUCT_SP_SUCCESS = "@user/PRODUCT_SP_SUCCESS";

// start get priceToken
export const LIST_PRICETOKEN_REQUEST = "@user/LIST_PRICETOKEN_REQUEST";
export const LIST_PRICETOKEN_SUCCESS = "@user/LIST_PRICETOKEN_SUCCESS";
export const LIST_PRICETOKEN_FAILED = "@user/LIST_PRICETOKEN_FAILED";

// investmentHistory
export const INVESTMENT_SUCCESS = "@user/INVESTMENT_SUCCESS";

// refund to
export const REFUND_SUCCESS = "@user/REFUND_SUCCESS";
