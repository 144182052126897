import React, { useMemo } from "react";
import { Flex, Box, Text, useMatchBreakpoints, IconButton } from "@raca2022/uikit";
import { useMenuContext } from "./context/Context";
import Logo from "./Logo";
import MenuList, { MenuListMobile } from "./MenuList";
import Connect from "./ButtonConnect";
import { OpenMenu } from "../Svg";

import { MenuWrapper, ButtonConect, WrapperMobile } from "./styledMenu";

const Menu = () => {
  const { isLg } = useMatchBreakpoints();
  const { openSidebar }: any = useMenuContext();

  const optionMemu = useMemo<any>(() => {
    if (!isLg) {
      return (
        <Flex>
          <Logo href="https://footdex.io/" />
          <MenuList />
        </Flex>
      );
    }
    if (isLg) {
      return (
        <Flex>
          <WrapperMobile>
            <IconButton className="bt-mobile" onClick={() => openSidebar()} startIcon={<OpenMenu width="30px" />} />
          </WrapperMobile>
          <Logo href="https://footdex.io/" />
        </Flex>
      );
    }
  }, [isLg]);

  return (
    <React.StrictMode>
      <MenuWrapper>
        <React.StrictMode>
          <Box className="fl-left">{optionMemu}</Box>
        </React.StrictMode>
        <Box>
          <Connect />
        </Box>
      </MenuWrapper>
      <MenuListMobile />
    </React.StrictMode>
  );
};

export default Menu;
