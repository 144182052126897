import styled, { keyframes } from "styled-components";
import { ButtonV1 } from "@raca2022/uikit";
// import pointer from "../../assets/images/cursor/cursor.svg";

const flast = keyframes`
   /* 0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  } */
  0% {
    background-position: 4% 0%;
  }
  50% {
    background-position: 97% 100%;
  }
  100% {
    background-position: 4% 0%;
  }
`;

const Button = styled(ButtonV1)`
  position: relative;
  border: none;
  background: linear-gradient(0deg, #671b1b, #8e1e1e, #671b1b, #691b1b);
  /* background-size: 600%; */
  background-size: 1000% 1000%;
  animation: ${flast} 10s linear infinite;
  box-shadow: 0px 8px 34px rgba(40, 44, 109, 0.31);
  border-radius: 7px;
  width: 100%;
  color: #fff;
  overflow: hidden;
  box-shadow: none;
  cursor: url("/assets/cursor/cursor.svg") 15 15, move !important;

  font-family: "PanchangSemi";
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: normal;
  white-space: nowrap;
  transition: background 0.3s ease;

  :hover {
    text-decoration: none;
    color: #671b1b;
  }

  :hover {
    opacity: 1 !important;
    background: #ea6e6e;
  }

  :active {
    box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.3), -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
      inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2), inset 4px 4px 6px 0 rgba(0, 0, 0, 0.2);
  }
  @media only screen and (max-width: 376px) {
    font-size: 10px;
    max-width: 130px;
  }
`;
export default Button;

/*

   :before {
    position: absolute;
    content: "";
    display: inline-block;
    top: -180px;
    left: 0;
    width: 30px;
    height: 100%;
    background-color: #fff;
    animation: ${flast} 3s ease-in-out infinite;
  } 

*/
