import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Flex, Box, Text } from "@raca2022/uikit";
import LogoFt from "../../../assets/imgQuata/Logo/LogoFt.png";
interface LogoProps {
  href: string;
}

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  cursor: url("/assets/cursor/cursor.svg") 15 15, move !important;

  .desktop-text {
    display: block;
  }

  @media only screen and (max-width: 1310px) {
    .desktop-text {
      display: none;
    }
  }

  @media only screen and (max-width: 1024px) {
    .desktop-text {
      display: block;
    }
  }
  @media only screen and (max-width: 840px) {
    .desktop-icon {
      display: inline-block;
      width: 94px;
    }
  }

  @media only screen and (max-width: 440px) {
    .desktop-text {
      display: none;
    }
  }
`;

const Heading = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  font-family: "PanchangBold";
  display: inline-block;
  background: linear-gradient(180deg, #e70303 13.39%, #8e2121 92.19%, #fff 100%, #fff 47%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;
const HeadingV1 = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  font-family: "PanchangBold";
  display: inline-block;

  background: linear-gradient(119.25deg, #dfdfdf 11.11%, #909090 83.76%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const IconOrbit = styled.img`
  max-width: 100%;
  width: 120px;
  height: 100%;
  display: block;

  @media only screen and (max-width: 860px) {
    /* width: 50%; */
    display: inline-block;
  }
`;
const Logo: React.FC<LogoProps> = ({ href }) => {
  const isAbsoluteUrl = href.startsWith("http");
  const innerLogo = (
    <>
      <Flex>
        <Box className="desktop-icon">
          <IconOrbit src={LogoFt} alt="icon-orbit" />
        </Box>
        {/* <Box className="desktop-text" pl="16px">
          <Heading>Raca</Heading>
          <HeadingV1>Fi</HeadingV1>
        </Box> */}
      </Flex>
    </>
  );

  return (
    <Flex>
      {isAbsoluteUrl ? (
        <StyledLink as="a" href={href} aria-label="Raca home page">
          {innerLogo}
        </StyledLink>
      ) : (
        <StyledLink to={href} aria-label="Raca home page">
          {innerLogo}
        </StyledLink>
      )}
    </Flex>
  );
};

export default React.memo(Logo);
