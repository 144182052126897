import React from "react";
import { SvgProps, Svg } from "@raca2022/uikit";

const LogoRaca: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 250 146" fill="none" {...props}>
      <g clip-path="url(#clip0_156_124542)">
        <path d="M71.9787 1.48102H40.7798C37.9646 1.48102 35.2636 2.59429 33.266 4.57799L0.888672 36.7298" stroke="url(#paint0_linear_156_124542)" stroke-width="1.18483" />
        <path d="M26.5715 11.5521L4.51052 33.6131C2.51985 35.6037 1.39715 38.3008 1.38732 41.1161L1.2279 86.745" stroke="url(#paint1_linear_156_124542)" stroke-width="1.18483" />
        <path d="M248.519 71.3863L248.519 102.585C248.519 105.4 247.406 108.101 245.422 110.099L213.27 142.476" stroke="url(#paint2_linear_156_124542)" stroke-width="1.18483" />
        <path d="M177.725 142.773L208.924 142.773C211.739 142.773 214.44 141.659 216.438 139.676L248.815 107.524" stroke="url(#paint3_linear_156_124542)" stroke-width="1.18483" />
        <path d="M4.29235 69.1543L68.8527 4.32384C70.8538 2.31441 73.5728 1.18481 76.4087 1.18481H238.152C244.041 1.18481 248.815 5.95902 248.815 11.8483V67.5747C248.815 70.3951 247.698 73.1007 245.708 75.0992L181.147 139.93C179.146 141.939 176.427 143.069 173.591 143.069H11.8483C5.95903 143.069 1.18481 138.294 1.18481 132.405V76.6788C1.18481 73.8584 2.30218 71.1528 4.29235 69.1543Z" fill="url(#paint4_linear_156_124542)" stroke="url(#paint5_linear_156_124542)" stroke-width="2.36967" />
        <g filter="url(#filter0_d_156_124542)">
          <path d="M29.8437 123.912C29.8437 121.908 28.5925 120.941 25.8058 120.784L20.5167 120.472C19.6352 120.415 19.2655 120.329 19.2655 119.789C19.2655 119.206 19.6494 119.064 22.2655 119.064C24.6968 119.064 27.256 119.334 28.9053 119.661L29.3745 117.486C27.4551 117.073 24.6541 116.803 22.9338 116.803C18.4409 116.803 16.5356 117.685 16.5356 119.86C16.5356 121.666 17.6304 122.633 20.6447 122.775L26.0048 123.088C26.7584 123.159 27.1138 123.23 27.1138 123.827C27.1138 124.41 26.7015 124.595 24.4266 124.595C21.5119 124.595 19.0949 124.211 17.1044 123.77L16.692 126.088C18.4409 126.514 21.2418 126.855 23.6589 126.855C28.4503 126.855 29.8437 125.96 29.8437 123.912ZM37.9801 119.419L35.236 124.453L32.9042 119.419H30.1033L33.5583 126.671H36.6436L39.6151 121.367L42.5867 126.671H45.6862L49.3971 119.419H46.5535L44.1649 124.481L41.364 119.419H37.9801ZM61.0174 119.746C59.4107 119.533 57.3207 119.277 54.8183 119.277C51.5624 119.277 49.3302 120.201 49.3302 123.116C49.3302 125.818 51.0505 126.813 53.624 126.813C55.3586 126.813 57.0363 126.315 58.4581 125.704L58.6572 126.671H61.0174V119.761H61.0031L61.0174 119.746ZM52.0174 123.017C52.0174 121.879 52.8704 121.438 54.5482 121.438C55.7425 121.438 57.1358 121.509 58.3302 121.623V123.884C56.8088 124.325 55.387 124.595 54.1501 124.595C52.6003 124.595 52.0174 124.168 52.0174 123.017ZM65.1451 129.301V126.457C66.2826 126.642 67.6048 126.813 68.9556 126.813C72.0977 126.813 74.1451 125.803 74.1451 122.988C74.1451 120.301 72.4958 119.277 69.8513 119.277C68.1025 119.277 66.4105 119.789 65.003 120.415L64.8181 119.419H62.4579V129.301H65.1451ZM69.3537 121.495C70.9034 121.495 71.4579 121.936 71.4579 123.031C71.4579 124.197 70.6902 124.652 69.0835 124.652C67.8608 124.652 66.3821 124.538 65.1451 124.41V122.206C66.7233 121.765 68.2162 121.495 69.3537 121.495Z" fill="url(#paint6_linear_156_124542)" />
        </g>
        <g opacity="0.5">
          <path d="M172.393 56.564L166.457 62.5119" stroke="url(#paint7_linear_156_124542)" stroke-width="0.888626" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M152.251 56.564H172.393" stroke="url(#paint8_linear_156_124542)" stroke-width="0.888626" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M152.251 49.4787L158.187 43.5308" stroke="url(#paint9_linear_156_124542)" stroke-width="0.888626" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M172.393 49.4787H152.251" stroke="url(#paint10_linear_156_124542)" stroke-width="0.888626" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <g opacity="0.5" className="cus-hover">
          <path d="M172.393 87.3696L166.457 93.3175" stroke="url(#paint11_linear_156_124542)" stroke-width="0.888626" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M152.251 87.3696H172.393" stroke="url(#paint12_linear_156_124542)" stroke-width="0.888626" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M152.251 80.2844L158.187 74.3365" stroke="url(#paint13_linear_156_124542)" stroke-width="0.888626" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M172.393 80.2844H152.251" stroke="url(#paint14_linear_156_124542)" stroke-width="0.888626" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <g opacity="0.5" className="cus-hover center">
          <path d="M204.976 56.564L199.04 62.5118" stroke="url(#paint15_linear_156_124542)" stroke-width="0.888626" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M184.834 56.564H204.976" stroke="url(#paint16_linear_156_124542)" stroke-width="0.888626" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M184.834 49.4787L190.77 43.5308" stroke="url(#paint17_linear_156_124542)" stroke-width="0.888626" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M204.976 49.4787H184.834" stroke="url(#paint18_linear_156_124542)" stroke-width="0.888626" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <g opacity="0.5" className="cus-hover">
          <path d="M204.976 25.7583L199.04 31.7062" stroke="url(#paint19_linear_156_124542)" stroke-width="0.888626" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M184.834 25.7583H204.976" stroke="url(#paint20_linear_156_124542)" stroke-width="0.888626" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M184.834 18.673L190.77 12.7251" stroke="url(#paint21_linear_156_124542)" stroke-width="0.888626" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M204.976 18.673H184.834" stroke="url(#paint22_linear_156_124542)" stroke-width="0.888626" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <g opacity="0.5">
          <path d="M204.976 87.3696L199.04 93.3175" stroke="url(#paint23_linear_156_124542)" stroke-width="0.888626" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M184.834 87.3696H204.976" stroke="url(#paint24_linear_156_124542)" stroke-width="0.888626" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M184.834 80.2844L190.77 74.3365" stroke="url(#paint25_linear_156_124542)" stroke-width="0.888626" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M204.976 80.2844H184.834" stroke="url(#paint26_linear_156_124542)" stroke-width="0.888626" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <g opacity="0.5" className="cus-hover">
          <path d="M237.559 56.564L231.623 62.5119" stroke="url(#paint27_linear_156_124542)" stroke-width="0.888626" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M217.417 56.564H237.559" stroke="url(#paint28_linear_156_124542)" stroke-width="0.888626" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M217.417 49.4787L223.353 43.5308" stroke="url(#paint29_linear_156_124542)" stroke-width="0.888626" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M237.559 49.4787H217.417" stroke="url(#paint30_linear_156_124542)" stroke-width="0.888626" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <g opacity="0.5" className="cus-hover">
          <path d="M237.559 25.7583L231.623 31.7062" stroke="url(#paint31_linear_156_124542)" stroke-width="0.888626" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M217.417 25.7583H237.559" stroke="url(#paint32_linear_156_124542)" stroke-width="0.888626" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M217.417 18.673L223.353 12.7251" stroke="url(#paint33_linear_156_124542)" stroke-width="0.888626" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M237.559 18.673H217.417" stroke="url(#paint34_linear_156_124542)" stroke-width="0.888626" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </g>
      </g>
      <defs>
        <filter id="filter0_d_156_124542" x="14.166" y="116.803" width="62.3487" height="17.237" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="2.36967" />
          <feGaussianBlur stdDeviation="1.18483" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_156_124542" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_156_124542" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_156_124542" x1="6.2204" y1="33.1753" x2="49.2505" y2="-16.7499" gradientUnits="userSpaceOnUse">
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.622682" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint1_linear_156_124542" x1="2.48457" y1="80.4615" x2="-2.39102" y2="14.7322" gradientUnits="userSpaceOnUse">
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.622682" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint2_linear_156_124542" x1="216.825" y1="137.145" x2="266.75" y2="94.1145" gradientUnits="userSpaceOnUse">
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.622682" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint3_linear_156_124542" x1="243.483" y1="111.078" x2="200.453" y2="161.003" gradientUnits="userSpaceOnUse">
          <stop stop-color="#DB0101" stop-opacity="0" />
          <stop offset="0.622682" stop-color="#DB0101" />
          <stop offset="1" stop-color="#DB0101" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint4_linear_156_124542" x1="249" y1="0.99999" x2="1.18482" y2="138.033" gradientUnits="userSpaceOnUse">
          <stop offset="0.0432349" stop-color="#0A0A0A" />
          <stop offset="0.484375" stop-color="#070C10" />
          <stop offset="1" stop-color="#060B0F" />
        </linearGradient>
        <linearGradient id="paint5_linear_156_124542" x1="248.815" y1="0.888603" x2="1.18482" y2="143.069" gradientUnits="userSpaceOnUse">
          <stop stop-color="#A60303" />
          <stop offset="0.494792" stop-color="#B30303" stop-opacity="0" />
          <stop offset="1" stop-color="#C00202" />
        </linearGradient>
        <linearGradient id="paint6_linear_156_124542" x1="45.4954" y1="114.214" x2="45.4954" y2="129.186" gradientUnits="userSpaceOnUse">
          <stop stop-color="#E70303" />
          <stop offset="1" stop-color="#8E2121" />
        </linearGradient>
        <linearGradient id="paint7_linear_156_124542" x1="169.425" y1="56.564" x2="169.425" y2="62.5119" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D5D5D5" />
          <stop offset="1" stop-color="#919191" />
        </linearGradient>
        <linearGradient id="paint8_linear_156_124542" x1="162.322" y1="56.564" x2="162.322" y2="57.564" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D5D5D5" />
          <stop offset="1" stop-color="#919191" />
        </linearGradient>
        <linearGradient id="paint9_linear_156_124542" x1="155.219" y1="43.5308" x2="155.219" y2="49.4787" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D5D5D5" />
          <stop offset="1" stop-color="#919191" />
        </linearGradient>
        <linearGradient id="paint10_linear_156_124542" x1="162.322" y1="49.4787" x2="162.322" y2="50.4787" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D5D5D5" />
          <stop offset="1" stop-color="#919191" />
        </linearGradient>
        <linearGradient id="paint11_linear_156_124542" x1="169.425" y1="87.3696" x2="169.425" y2="93.3175" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D5D5D5" />
          <stop offset="1" stop-color="#919191" />
        </linearGradient>
        <linearGradient id="paint12_linear_156_124542" x1="162.322" y1="87.3696" x2="162.322" y2="88.3696" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D5D5D5" />
          <stop offset="1" stop-color="#919191" />
        </linearGradient>
        <linearGradient id="paint13_linear_156_124542" x1="155.219" y1="74.3365" x2="155.219" y2="80.2844" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D5D5D5" />
          <stop offset="1" stop-color="#919191" />
        </linearGradient>
        <linearGradient id="paint14_linear_156_124542" x1="162.322" y1="80.2844" x2="162.322" y2="81.2844" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D5D5D5" />
          <stop offset="1" stop-color="#919191" />
        </linearGradient>
        <linearGradient id="paint15_linear_156_124542" x1="202.008" y1="56.564" x2="202.008" y2="62.5118" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D5D5D5" />
          <stop offset="1" stop-color="#919191" />
        </linearGradient>
        <linearGradient id="paint16_linear_156_124542" x1="194.905" y1="56.564" x2="194.905" y2="57.564" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D5D5D5" />
          <stop offset="1" stop-color="#919191" />
        </linearGradient>
        <linearGradient id="paint17_linear_156_124542" x1="187.802" y1="43.5308" x2="187.802" y2="49.4787" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D5D5D5" />
          <stop offset="1" stop-color="#919191" />
        </linearGradient>
        <linearGradient id="paint18_linear_156_124542" x1="194.905" y1="49.4787" x2="194.905" y2="50.4787" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D5D5D5" />
          <stop offset="1" stop-color="#919191" />
        </linearGradient>
        <linearGradient id="paint19_linear_156_124542" x1="202.008" y1="25.7583" x2="202.008" y2="31.7062" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D5D5D5" />
          <stop offset="1" stop-color="#919191" />
        </linearGradient>
        <linearGradient id="paint20_linear_156_124542" x1="194.905" y1="25.7583" x2="194.905" y2="26.7583" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D5D5D5" />
          <stop offset="1" stop-color="#919191" />
        </linearGradient>
        <linearGradient id="paint21_linear_156_124542" x1="187.802" y1="12.7251" x2="187.802" y2="18.673" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D5D5D5" />
          <stop offset="1" stop-color="#919191" />
        </linearGradient>
        <linearGradient id="paint22_linear_156_124542" x1="194.905" y1="18.673" x2="194.905" y2="19.673" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D5D5D5" />
          <stop offset="1" stop-color="#919191" />
        </linearGradient>
        <linearGradient id="paint23_linear_156_124542" x1="202.008" y1="87.3696" x2="202.008" y2="93.3175" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D5D5D5" />
          <stop offset="1" stop-color="#919191" />
        </linearGradient>
        <linearGradient id="paint24_linear_156_124542" x1="194.905" y1="87.3696" x2="194.905" y2="88.3696" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D5D5D5" />
          <stop offset="1" stop-color="#919191" />
        </linearGradient>
        <linearGradient id="paint25_linear_156_124542" x1="187.802" y1="74.3365" x2="187.802" y2="80.2844" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D5D5D5" />
          <stop offset="1" stop-color="#919191" />
        </linearGradient>
        <linearGradient id="paint26_linear_156_124542" x1="194.905" y1="80.2844" x2="194.905" y2="81.2844" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D5D5D5" />
          <stop offset="1" stop-color="#919191" />
        </linearGradient>
        <linearGradient id="paint27_linear_156_124542" x1="234.591" y1="56.564" x2="234.591" y2="62.5119" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D5D5D5" />
          <stop offset="1" stop-color="#919191" />
        </linearGradient>
        <linearGradient id="paint28_linear_156_124542" x1="227.488" y1="56.564" x2="227.488" y2="57.564" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D5D5D5" />
          <stop offset="1" stop-color="#919191" />
        </linearGradient>
        <linearGradient id="paint29_linear_156_124542" x1="220.385" y1="43.5308" x2="220.385" y2="49.4787" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D5D5D5" />
          <stop offset="1" stop-color="#919191" />
        </linearGradient>
        <linearGradient id="paint30_linear_156_124542" x1="227.488" y1="49.4787" x2="227.488" y2="50.4787" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D5D5D5" />
          <stop offset="1" stop-color="#919191" />
        </linearGradient>
        <linearGradient id="paint31_linear_156_124542" x1="234.591" y1="25.7583" x2="234.591" y2="31.7062" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D5D5D5" />
          <stop offset="1" stop-color="#919191" />
        </linearGradient>
        <linearGradient id="paint32_linear_156_124542" x1="227.488" y1="25.7583" x2="227.488" y2="26.7583" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D5D5D5" />
          <stop offset="1" stop-color="#919191" />
        </linearGradient>
        <linearGradient id="paint33_linear_156_124542" x1="220.385" y1="12.7251" x2="220.385" y2="18.673" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D5D5D5" />
          <stop offset="1" stop-color="#919191" />
        </linearGradient>
        <linearGradient id="paint34_linear_156_124542" x1="227.488" y1="18.673" x2="227.488" y2="19.673" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D5D5D5" />
          <stop offset="1" stop-color="#919191" />
        </linearGradient>
        <clipPath id="clip0_156_124542">
          <rect width="250" height="146" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default LogoRaca;
