import React from "react";
import { SvgProps, Svg } from "@raca2022/uikit";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 80 80" fill="#fff" {...props}>
      <rect width="80" height="80" rx="40" fill="url(#paint0_linear_95_76453)" />
      <path
        d="M59.6236 28.2394V32.5094C59.6236 34.0027 58.9236 35.4027 57.7103 36.266L32.0436 55.0727C30.387 56.286 28.1236 56.286 26.4903 55.0494L23.1303 52.5294C21.6136 51.386 20.377 48.9127 20.377 47.0227V28.2394C20.377 25.626 22.3836 22.7327 24.8336 21.8227L37.597 17.0394C38.927 16.5494 41.0736 16.5494 42.4036 17.0394L55.167 21.8227C57.617 22.7327 59.6236 25.626 59.6236 28.2394Z"
        fill="url(#paint1_linear_95_76453)"
      />
      <path
        d="M55.9124 40.794C57.4524 39.674 59.6224 40.7706 59.6224 42.684V47.0706C59.6224 48.9606 58.3858 51.4106 56.8691 52.554L44.1058 62.0973C42.9858 62.914 41.4924 63.334 39.9991 63.334C38.5058 63.334 37.0124 62.914 35.8924 62.074L33.9558 60.6273C32.6958 59.694 32.6958 57.804 33.9791 56.8706L55.9124 40.794Z"
        fill="url(#paint2_linear_95_76453)"
      />
      <defs>
        <linearGradient id="paint0_linear_95_76453" x1="40" y1="0" x2="40" y2="80" gradientUnits="userSpaceOnUse">
          <stop stop-color="#3C3D3E" />
          <stop offset="1" stop-color="#3C3D3E" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_95_76453"
          x1="27.5372"
          y1="36.326"
          x2="52.7511"
          y2="36.3339"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C80E0E" />
          <stop offset="1" stop-color="#AE1717" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_95_76453"
          x1="37.8683"
          y1="51.8389"
          x2="54.9629"
          y2="51.8451"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C80E0E" />
          <stop offset="1" stop-color="#AE1717" />
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default Icon;
