import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actLoginAdmin } from "../redux/action/AuthManagerAction";
import { useActiveWeb3React } from ".";

const useLogin = () => {
  const { account } = useActiveWeb3React();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const [values, setValues]: any = useState({
    Address: "",
    Signature: "",
    ReferalCode: "",
  });

  const params = new URLSearchParams(window.location.search);
  const foo = params.get("referral"); // bar

  useMemo(() => {
    if (account) {
      setValues((prev: any) => ({ ...prev, Address: account }));
    }
    if (search.indexOf("?referral=") !== -1) {
      setValues((prev: any) => ({ ...prev, ReferalCode: foo }));
    }
  }, [account, search]);

  const _isLogin = (values: any) => {
    if (values.Address !== "") {
      dispatch(actLoginAdmin(values));
    }
  };

  useEffect(() => {
    _isLogin(values);
  }, [values]);
};

export default useLogin;
