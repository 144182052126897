import React from "react";
import { SvgProps, Svg } from "@raca2022/uikit";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 14 10" fill="none" {...props}>
     <path d="M8.04266 8.56086C7.73755 8.86595 7.73755 9.36062 8.04266 9.66571C8.34776 9.9708 8.84234 9.9708 9.14748 9.66571L13.3141 5.49901C13.4667 5.34651 13.543 5.14651 13.543 4.94662C13.543 4.84068 13.5219 4.73964 13.4837 4.64755C13.4456 4.55547 13.3891 4.46912 13.3141 4.39422L9.14748 0.227554C8.84234 -0.0775503 8.34776 -0.0775503 8.04266 0.227554C7.73755 0.532658 7.73755 1.02724 8.04266 1.33235L10.8756 4.16537L0.782552 4.16537C0.351094 4.16537 0.00130224 4.51516 0.0013022 4.94662C0.00130216 5.37807 0.351094 5.72787 0.782552 5.72787L10.8756 5.72787L8.04266 8.56086Z" fill="#B71414"/>
    </Svg>
  );
};

export default Icon;
