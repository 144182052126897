import React from "react";
import { SvgProps, Svg } from "@raca2022/uikit";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 40 40" fill="#fff" {...props}>
      <path
        d="M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM28.06 21.06L22.06 27.06C21.76 27.36 21.38 27.5 21 27.5C20.62 27.5 20.24 27.36 19.94 27.06C19.36 26.48 19.36 25.52 19.94 24.94L23.38 21.5H13C12.18 21.5 11.5 20.82 11.5 20C11.5 19.18 12.18 18.5 13 18.5H23.38L19.94 15.06C19.36 14.48 19.36 13.52 19.94 12.94C20.52 12.36 21.48 12.36 22.06 12.94L28.06 18.94C28.64 19.52 28.64 20.48 28.06 21.06Z"
        fill="#A9A9A9"
      />
    </Svg>
  );
};

export default Icon;
