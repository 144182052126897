import React from "react";
import { SvgProps, Svg } from "@raca2022/uikit";

const LogoRaca: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 44 46" fill="none" {...props}>
      <g clip-path="url(#clip0_8_18438)">
        <path
          d="M31.6728 42.2486C31.6728 42.2486 40.4832 38.3647 42.4251 32.1709C42.0163 33.5405 41.403 34.8079 40.6262 35.973C39.829 37.0564 38.9296 38.0785 37.9484 38.9984C35.5159 41.2878 32.5518 43.0458 29.2607 44.027C29.2812 44.027 30.3033 43.4955 31.6728 42.2486Z"
          fill="#600D0D"
        />
        <path
          d="M36.0065 36.5661C38.2347 32.2121 39.6451 25.6708 37.4988 16.2881C37.6827 16.4312 37.8872 16.5947 38.0507 16.7582C38.0711 16.7787 38.0916 16.7991 38.112 16.7991C39.4407 17.9643 40.5446 19.3952 41.3213 21.0101C41.3213 21.0305 41.3418 21.0305 41.3418 21.051C42.4456 23.0951 43.0793 25.4459 43.0793 27.9398C43.0793 27.9807 43.0793 28.0215 43.0793 28.0624C43.0589 29.4933 42.8545 30.8834 42.4456 32.1712C40.5037 38.3854 31.6934 42.2489 31.6934 42.2489C32.5315 41.4925 33.5127 40.4704 34.4325 39.1622C35.0662 38.4672 35.5977 37.5677 36.0065 36.5661Z"
          fill="#6B0E0E"
        />
        <path
          d="M10.8426 41.3288C10.8426 41.3288 10.8221 41.3288 10.8426 41.3288C10.8221 41.3084 10.8221 41.3084 10.8017 41.3084C10.4951 41.104 10.168 40.8791 9.88184 40.6543C12.0486 41.4106 14.3585 41.8194 16.7706 41.8194C23.455 41.8194 29.4035 38.7123 33.2465 33.8677C34.2277 32.6207 35.0658 31.2716 35.7608 29.8407C35.8222 29.718 35.8835 29.5954 35.9448 29.4727V29.4523C35.9448 29.4318 35.9653 29.4318 35.9653 29.4114C35.9653 29.391 35.9857 29.3705 35.9857 29.3705C36.0879 29.1865 36.1697 29.0026 36.2514 28.8186C36.6194 28.0214 36.926 27.1628 37.1304 26.2838C37.1713 26.1407 37.1918 25.9977 37.2326 25.8546V25.8341C37.3962 25.18 37.4984 24.5054 37.5801 23.8513C37.6619 23.422 37.7232 22.9927 37.7641 22.5635C37.7846 22.727 38.2547 31.2307 36.0061 36.5864C35.5155 37.5676 34.9636 38.4261 34.4117 39.2029C33.4714 40.5112 32.5106 41.5332 31.6725 42.2896C30.303 43.5161 29.2604 44.0476 29.2604 44.0476C27.2572 44.6608 25.1108 44.9879 22.9031 44.9879C18.4673 44.947 14.3177 43.6183 10.8426 41.3288Z"
          fill="#751111"
        />
        <path
          d="M37.4991 16.288C39.6454 25.6707 38.235 32.1916 36.0068 36.566C38.2554 31.2104 37.7852 22.7067 37.7648 22.5431C38.0101 20.4377 37.8875 18.3117 37.4582 16.2676C37.4786 16.2676 37.4991 16.288 37.4991 16.288Z"
          fill="#920E0E"
        />
        <path
          d="M37.2741 16.1245C37.2741 16.1858 37.2946 16.2267 37.2946 16.288C37.4377 17.5554 37.499 18.7615 37.4581 19.8857C37.4581 19.6609 37.3968 15.5317 33.5742 14.1621C34.0035 14.3052 34.4123 14.4687 34.8007 14.6527C35.3935 14.9184 35.9863 15.2455 36.5382 15.593C36.5382 15.593 36.5791 15.6135 36.6405 15.6543C36.6609 15.6748 36.6813 15.6748 36.7018 15.6952C36.7427 15.7157 36.7835 15.7566 36.8244 15.777C36.9471 15.8792 37.1106 16.0019 37.2741 16.1245Z"
          fill="#920E0E"
        />
        <path
          d="M37.2739 16.1244C37.0899 16.0018 36.9264 15.8791 36.8037 15.7974C36.9672 15.8996 37.1308 16.0018 37.2739 16.1244Z"
          fill="#A80B0B"
        />
        <path
          d="M37.4579 19.906C37.417 21.8683 37.1309 23.6468 36.7425 25.2208C36.7016 25.1799 36.6607 25.139 36.6403 25.0981C36.4972 21.9706 35.1276 17.6165 29.2813 15.1226H29.2609C29.2404 15.1226 29.22 15.1022 29.1791 15.0817C29.1587 15.0817 29.1587 15.0817 29.1382 15.0613C28.1161 14.632 26.9714 14.2641 25.6836 13.9779C26.8283 13.6508 28.0548 13.4873 29.3017 13.4873C29.6493 13.4873 29.9968 13.5077 30.3238 13.5282C30.3443 13.5282 30.3852 13.5282 30.4056 13.5282C31.4481 13.61 32.4498 13.8144 33.4105 14.121C33.4718 14.1414 33.5332 14.1619 33.5945 14.1823C37.417 15.5315 37.4579 19.6607 37.4579 19.906C37.4579 19.906 37.4579 19.8855 37.4579 19.906Z"
          fill="#850F0F"
        />
        <path
          d="M35.9248 29.4727C35.9452 29.4319 35.9657 29.391 35.9861 29.3501C35.9861 29.3705 35.9657 29.391 35.9657 29.391C35.9657 29.4114 35.9452 29.4114 35.9452 29.4319C35.9452 29.4523 35.9452 29.4523 35.9248 29.4727C35.9248 29.4727 35.9452 29.4727 35.9248 29.4727Z"
          fill="#751111"
        />
        <path
          d="M35.9243 29.4727C35.9243 29.4727 35.9447 29.4727 35.9243 29.4727C35.8834 29.5749 35.8221 29.6975 35.7607 29.8202C35.8016 29.718 35.8629 29.6157 35.9038 29.4931C35.9243 29.5135 35.9243 29.4931 35.9243 29.4727Z"
          fill="#751111"
        />
        <path
          d="M36.0066 36.5659C35.5773 37.5676 35.0662 38.467 34.4121 39.1824C34.9845 38.4057 35.516 37.5471 36.0066 36.5659Z"
          fill="#6B0E0E"
        />
        <path
          d="M23.9053 1.01807C29.9969 1.28381 35.4343 4.02297 39.2569 8.25438C36.3542 5.39256 32.4907 3.49149 28.198 3.14399C26.8489 2.265 25.4179 1.54955 23.9053 1.01807Z"
          fill="#CA0606"
        />
        <path
          d="M25.4787 17.9233C30.262 18.7001 33.41 21.2962 35.0658 23.1155C31.8156 21.01 28.4836 21.3575 26.4803 21.889C26.4394 21.889 26.3986 21.9094 26.3577 21.9094C25.9897 22.0117 25.6422 22.1139 25.3152 22.257C25.2743 22.2774 25.2334 22.2774 25.1925 22.2978C25.0085 22.3592 24.8654 22.4205 24.7632 22.4818C24.6815 22.5227 24.6406 22.5431 24.6201 22.5431C21.186 24.158 18.8352 27.6535 18.8352 31.7009C18.8352 33.4998 19.3053 35.176 20.123 36.6274C20.0821 36.5865 20.0412 36.5456 20.0004 36.5047C19.9799 36.4843 19.9595 36.4638 19.939 36.4434C17.4452 34.0108 15.8916 30.6175 15.8916 26.8563C15.8916 25.8342 16.0143 24.8326 16.2187 23.8718C18.3446 18.0255 25.4787 17.9233 25.4787 17.9233Z"
          fill="#A40B0B"
        />
        <path
          d="M36.5173 24.9347C34.6571 22.8701 31.9792 21.5618 28.9743 21.5618C28.1158 21.5618 27.2777 21.664 26.4805 21.8684C28.4837 21.3369 31.8362 20.9894 35.0659 23.0949C36.0062 24.0966 36.456 24.8733 36.5173 24.9347Z"
          fill="#850F0F"
        />
        <path
          d="M29.1181 15.0409C28.4027 14.7752 25.2342 13.7735 21.2686 16.1243C22.5768 15.1431 24.0486 14.4072 25.6635 13.9575C26.9717 14.2641 28.1165 14.6116 29.1181 15.0409Z"
          fill="#A40B0B"
        />
        <path
          d="M24.7837 22.4614C24.7428 22.4819 24.6815 22.5023 24.6406 22.5227C24.6611 22.5227 24.702 22.5023 24.7837 22.4614Z"
          fill="#A40B0B"
        />
        <path
          d="M8.98242 15.8588C9.53434 12.3633 10.8222 8.09098 13.4387 3.14412C15.8712 1.97895 18.5491 1.24306 21.37 1.05908C23.4755 1.38615 25.5605 2.06072 27.5229 3.10324C27.2367 3.0828 26.9506 3.0828 26.6644 3.0828C21.8402 3.0828 17.4657 5.0043 14.2359 8.11142C14.2359 8.11142 14.2359 8.11142 14.2155 8.13186C13.8884 8.37716 10.352 11.4025 8.98242 15.8588Z"
          fill="#CA0606"
        />
        <path
          d="M23.9049 1.018C25.4175 1.56992 26.8485 2.28538 28.1976 3.14392C27.9727 3.12348 27.7479 3.10304 27.523 3.10304C25.5606 2.06052 23.4756 1.38595 21.3701 1.05888C21.8812 1.018 22.4126 0.997559 22.9237 0.997559C23.2507 0.997559 23.5778 0.997559 23.9049 1.018Z"
          fill="#CA0606"
        />
        <path
          d="M1.17383 26.1611C7.34718 34.1742 13.7249 36.8725 18.8149 37.4653C24.4568 38.1194 28.5042 36.1571 28.913 35.9526C28.9335 35.9322 28.9539 35.9322 28.9539 35.9322C29.1379 35.8504 29.3219 35.7687 29.4854 35.6869C25.07 39.6117 18.3243 39.8161 18.3243 39.8161C14.4608 39.8161 10.5769 38.6509 7.1632 36.2593V36.2593C4.08586 34.0893 2.1067 30.6621 1.32299 26.9791C1.26171 26.6912 1.21113 26.4165 1.17383 26.1611Z"
          fill="#920E0E"
        />
        <path
          d="M19.2645 17.9639C21.5744 17.5959 23.6595 17.6164 25.4788 17.923C25.4788 17.923 18.3447 18.0252 16.2188 23.8715C16.7298 21.6434 17.7928 19.6197 19.2645 17.9639Z"
          fill="#BB0808"
        />
        <path
          d="M29.2811 15.1021C35.1478 17.596 36.4969 21.9501 36.64 25.0776C36.5992 25.0163 36.5583 24.9754 36.5174 24.9345C36.4765 24.8732 36.0064 24.1169 35.0865 23.1152C33.4307 21.3164 30.2827 18.6998 25.4994 17.9231C23.6801 17.6164 21.5951 17.596 19.2852 17.964C19.8984 17.2894 20.573 16.6761 21.2884 16.1242C25.2745 13.7734 28.443 14.7751 29.138 15.0408C29.1584 15.0408 29.1584 15.0613 29.1789 15.0613C29.2198 15.0817 29.2402 15.0817 29.2606 15.1021C29.2606 15.1021 29.2606 15.1021 29.2811 15.1021Z"
          fill="#920E0E"
        />
        <path
          d="M32.7151 33.5815C28.9334 37.6902 23.6595 39.857 18.3242 39.8366C18.3242 39.8366 25.0699 39.6322 29.4853 35.7074C30.6709 35.135 31.7748 34.4196 32.7355 33.561C32.7355 33.561 32.7151 33.561 32.7151 33.5815Z"
          fill="#751111"
        />
        <path
          d="M8.63525 28.6959C8.73745 28.8799 12.3352 35.0123 18.8151 37.4858C13.7252 36.893 7.34743 34.1947 1.17408 26.1816C1.01054 25.1391 0.949219 24.0761 0.949219 22.9723C0.949219 20.0491 1.52158 17.2691 2.5641 14.7139L2.62543 14.7343C3.99501 20.9894 6.22314 25.4661 8.63525 28.6959Z"
          fill="#A40B0B"
        />
        <path
          d="M8.63477 28.6958C9.75905 30.188 10.9038 31.3941 12.0281 32.3957C16.0755 35.9526 19.7754 36.6271 20.1025 36.668H20.1229C20.1434 36.668 20.1434 36.668 20.1638 36.6884H20.2047C21.2472 36.9337 22.3306 37.0564 23.4345 37.0564C23.9455 37.0564 24.4565 37.036 24.9471 36.9746C25.1107 36.9542 25.2538 36.9337 25.3968 36.9133C26.6233 36.7498 27.8089 36.4227 28.9128 35.9526C28.4835 36.157 24.4361 38.1194 18.8147 37.4652C12.3347 34.9918 8.73697 28.8798 8.63477 28.6958Z"
          fill="#920E0E"
        />
        <path
          d="M8.63553 20.1105C8.63553 20.1105 8.26758 28.7982 12.0288 32.3959C10.9045 31.4147 9.73937 30.1882 8.63553 28.696C6.22343 25.4662 3.9953 20.9895 2.60527 14.7344L2.54395 14.714C4.60854 9.62402 8.51288 5.49482 13.4393 3.14404C10.8228 8.0909 9.53496 12.3427 8.98304 15.8587C8.73774 17.4327 8.63553 18.8432 8.63553 20.1105Z"
          fill="#BB0808"
        />
        <path
          d="M14.2156 8.11133C10.8632 11.3615 8.77811 15.92 8.77811 20.9486C8.77811 23.0541 9.14606 25.0778 9.82063 26.9584C9.82063 26.9584 8.61458 24.5668 8.63502 20.1105C8.63502 18.8636 8.73723 17.4327 8.98253 15.8587C10.3521 11.4024 13.8885 8.37707 14.2156 8.11133Z"
          fill="#A40B0B"
        />
        <path
          d="M20.1026 36.668C19.7959 36.6271 16.0756 35.9526 12.0282 32.3957C8.2669 28.798 8.63485 20.1104 8.63485 20.1104C8.63485 24.5666 9.82046 26.9583 9.82046 26.9583C10.0249 27.5715 10.2906 28.1439 10.5564 28.7162C11.1287 29.9223 11.8442 31.0261 12.6618 32.0687C13.5408 33.0907 14.5425 34.0106 15.6872 34.7465C16.9341 35.5642 18.3242 36.1979 19.7959 36.5862C19.8368 36.5862 19.8573 36.6067 19.8982 36.6067C19.9186 36.6067 19.939 36.6271 19.9799 36.6271C20.0208 36.6476 20.0617 36.6476 20.1026 36.668Z"
          fill="#A40B0B"
        />
      </g>
      <defs>
        <clipPath id="clip0_8_18438">
          <rect width="42.2323" height="44.072" fill="white" transform="translate(0.887695 0.936035)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default LogoRaca;
