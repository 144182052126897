import React from "react";
import { SvgProps, Svg } from "@raca2022/uikit";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 80 80" fill="#fff" {...props}>
      <rect width="80" height="80" rx="40" fill="url(#paint0_linear_95_76467)" />
      <path
        d="M46.9994 48.1667C46.9994 56.5433 40.2094 63.3333 31.8327 63.3333C23.456 63.3333 16.666 56.5433 16.666 48.1667C16.666 39.79 23.456 33 31.8327 33C32.2294 33 32.6494 33.0233 33.046 33.0467C40.4427 33.63 46.3693 39.5567 46.9527 46.9533C46.976 47.35 46.9994 47.77 46.9994 48.1667Z"
        fill="url(#paint1_linear_95_76467)"
      />
      <path
        d="M63.3342 31.8327C63.3342 39.4394 57.7342 45.716 50.4542 46.8127V46.6727C49.7308 37.6193 42.3808 30.2693 33.2575 29.546H33.1875C34.2842 22.266 40.5608 16.666 48.1675 16.666C56.5442 16.666 63.3342 23.456 63.3342 31.8327Z"
        fill="url(#paint2_linear_95_76467)"
      />
      <path
        d="M25.0427 16.666H18.9993C17.716 16.666 16.666 17.716 16.666 18.9993V25.0427C16.666 27.1193 19.186 28.1693 20.656 26.6993L26.6993 20.656C28.146 19.186 27.1193 16.666 25.0427 16.666Z"
        fill="url(#paint3_linear_95_76467)"
      />
      <path
        d="M54.9573 63.3326H61.0007C62.284 63.3326 63.334 62.2826 63.334 60.9993V54.956C63.334 52.8793 60.814 51.8293 59.344 53.2993L53.3007 59.3426C51.854 60.8126 52.8807 63.3326 54.9573 63.3326Z"
        fill="url(#paint4_linear_95_76467)"
      />
      <defs>
        <linearGradient id="paint0_linear_95_76467" x1="40" y1="0" x2="40" y2="80" gradientUnits="userSpaceOnUse">
          <stop stop-color="#3C3D3E" />
          <stop offset="1" stop-color="#3C3D3E" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_95_76467"
          x1="22.2001"
          y1="48.1668"
          x2="41.6876"
          y2="48.1729"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C80E0E" />
          <stop offset="1" stop-color="#AE1717" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_95_76467"
          x1="38.6876"
          y1="31.7394"
          x2="58.0551"
          y2="31.7455"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C80E0E" />
          <stop offset="1" stop-color="#AE1717" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_95_76467"
          x1="18.6198"
          y1="22.0257"
          x2="25.4997"
          y2="22.0279"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C80E0E" />
          <stop offset="1" stop-color="#AE1717" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_95_76467"
          x1="54.5788"
          y1="57.973"
          x2="61.4587"
          y2="57.9752"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C80E0E" />
          <stop offset="1" stop-color="#AE1717" />
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default Icon;
