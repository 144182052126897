import { createGlobalStyle } from "styled-components";
// import BannerBody from "/assets/Banner/BannerBody.png";

const GlobalStyle = createGlobalStyle`
  * {
    font-family: "Chillax";
  }
  body {
    overflow-x: hidden;
    z-index: 2;
    width: 100%;
    background-image: url("/assets/Banner/BannerBody.png");
    background-size: 1440px 934px;
    background-color: rgba(0, 0, 0, 0.9);
    color: #fff;
  
    img {
      height: auto;
      max-width: 100%;
    }
  }
  body::-webkit-scrollbar{
    width: 0px;
  }
  iframe {
    display: none;
  }

  li {
      list-style-type: none
    }
`;

export default GlobalStyle;
