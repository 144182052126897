import axios from "axios";
import { TOKEN, DOMAIN_RACA } from "../settings/config";

const Api = axios.create({
  baseURL: DOMAIN_RACA,
});

Api.interceptors.request.use(
  function (config) {
    const toKenUser = localStorage.getItem(TOKEN)!!;

    config.headers = {
      ...config.headers,
      "FBD-User-Token": toKenUser,
      // "RACAFI-User-Token": toKenUser,
    };
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export { Api };

// "FUBI-User-Token": toKenUser,
// RACAFI
