import React, { lazy, Suspense } from "react";
import { Switch, Router, useLocation, Route, BrowserRouter } from "react-router-dom";
import { ResetCSS, Box } from "@raca2022/uikit";
import Aos from "aos";
import { ToastContainer } from "react-toastify";
import { Button } from "../components/Button";
import { ScrollToTop } from "../components/ScrollTop";
import { Menu } from "../components/Menu";
import Footer from "../components/FooterQuataHome";
import Loading from "../components/Loading/LoadingDesktop";
import Social from "./Social";
import RedirectPathToHome from "./redirects";

import history from "../routerHistory";
import GlobalStyle from "../style";
import "aos/dist/aos.css";

//
const Home = lazy(() => import("./Home"));
const Dashboard = lazy(() => import("./Dashboard"));
const Investment = lazy(() => import("./Investment"));
const History = lazy(() => import("./History"));
const Affiliates = lazy(() => import("./affiliates"));
const Swap = lazy(() => import("./Swap"));
const Withdraw = lazy(() => import("./Withdraw"));
const Ecosystem = lazy(() => import("./Ecosystem"));
const TestInvesment = lazy(() => import("./TestInvesment/TestInvesment"));

const App = () => {
  Aos.init();
  return (
    <>
      <Suspense fallback={<Loading />}>
        <Router history={history}>
          <ResetCSS />
          <GlobalStyle />
          <Menu />
          {/* <Social /> */}
          <Box className="overFlow cus-pt-global" overflowX="hidden">
            <ScrollToTop>
              <Switch>
                {/* <Route path="/" exact>
                  <Home />
                </Route> */}
                {/* <Route path="/ecosystem" exact>
                  <Ecosystem />
                </Route> */}
                <Route path="/dashboard" exact>
                  <Dashboard />
                </Route>
                <Route path="/investment" exact>
                  <Investment />
                </Route>
                <Route path="/investment-test" exact>
                  <TestInvesment />
                </Route>
                <Route path="/transaction" exact>
                  <History />
                </Route>
                <Route path="/affiliates" exact>
                  <Affiliates />
                </Route>
                {/* <Route path="/swap" exact>
                  <Swap />
                </Route> */}
                <Route path="/withdraw" exact>
                  <Withdraw />
                </Route>
                <Route component={RedirectPathToHome} />
              </Switch>
            </ScrollToTop>
          </Box>
          <Footer />
        </Router>
      </Suspense>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default App;
