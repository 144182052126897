import React from "react";
import { SvgProps, Svg } from "@raca2022/uikit";

const LogoRaca: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M0 10.0601C0.000563232 12.4561 0.850471 14.7733 2.39696 16.5953C3.94345 18.4173 6.08511 19.6245 8.437 20V12.9679H5.9V10.0601H8.44V7.84651C8.38391 7.32842 8.44189 6.80427 8.60982 6.31127C8.77775 5.81827 9.0515 5.36852 9.41164 4.99396C9.77178 4.6194 10.2095 4.32923 10.6936 4.14404C11.1778 3.95886 11.6965 3.8832 12.213 3.92246C12.963 3.93453 13.713 4.00195 14.453 4.12369V6.59786H13.189C12.9742 6.56955 12.7558 6.59017 12.5499 6.65821C12.3441 6.72624 12.1561 6.83995 11.9998 6.99093C11.8435 7.14191 11.7229 7.3263 11.647 7.53047C11.571 7.73464 11.5416 7.95337 11.561 8.1705V10.0601H14.332L13.889 12.9689H11.561V20C13.4486 19.6998 15.2112 18.8618 16.6402 17.585C18.0691 16.3082 19.1045 14.6461 19.6238 12.7957C20.143 10.9452 20.1244 8.98372 19.5702 7.14354C19.0159 5.30335 17.9492 3.66149 16.4964 2.41235C15.0435 1.1632 13.2653 0.359089 11.3724 0.0952063C9.4794 -0.168676 7.55092 0.118724 5.81525 0.923381C4.07958 1.72804 2.60942 3.01625 1.5788 4.63551C0.548194 6.25476 0.00030337 8.13724 0 10.0601Z"
        fill="#EA6E6E"
      />
    </Svg>
  );
};

export default LogoRaca;
